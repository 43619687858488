import MojolockBase from './mojolock_base';

class MojolockG4MojoknobSilver extends MojolockBase {
  #price;

  constructor(account) {
    super();
    this.#price = account.mojolock_g4_and_mojoknob_silver_unit_price / 100;
  }

  getPrice() {
    return this.#price;
  }
}

export default MojolockG4MojoknobSilver;
class VideoForm {
  bindEvents() {
    document.querySelector('#edit-video').addEventListener('click', () => {
      document.querySelector('#video form').dispatchEvent(new CustomEvent('partial-form:enable'));
    });
  }

  init() {
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('#video form');
  if (form) {
    new VideoForm().init();
  }
});


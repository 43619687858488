class EditProperties {
  constructor() {
    this.refreshMapWidget = this.refreshMapWidget.bind(this);
  }

  refreshMapWidget() {
    // Update map widget
    let locations = [];
    let i = 1;
    [...document.querySelectorAll('.partialform-edit .address_item')].filter((el) => el.style.display !== 'none').forEach(function(el) {
      let lat = el.dataset.lat,
        long = el.dataset.long;
      locations.push([lat, long, i]);
      i++;
    });
  
    const new_address = document.querySelector('#address_address').value,
      city = document.querySelector('#address_city').value,
      state = document.querySelector('#address_state').value;
  
    if(new_address && city && state) {
      this.addNewLocationToMap(locations, i, { address: new_address, city: city, state: state });
    } else {
      this.updateStaticMapUrl(locations);
    }
  }
  
  createMapboxMarker(latitude, longitude, label) {
    return 'pin-m-' + label + '+ff0000(' + [longitude, latitude].join(',') + ')';
  }
  
  updateStaticMapUrl(original_locations) {
    let locations = original_locations.slice(0, 99).filter(function(location) {
      return !isNaN(parseFloat(location[0]) * parseFloat(location[1]));
    });
  
    let property_map = document.getElementById('property_map');
    if (property_map) {
      const map_provider = property_map.getAttribute('data-provider');
      let map_url;
      if(locations.length === 0) {
        map_url = this.blankMapUrl();
      } else if(map_provider === 'HERE') {
        map_url = this.hereMapUrl(property_map, locations);
      } else { // MapBox or unexpected provider
        map_url = this.mapboxMapUrl(property_map, locations);
      }
    
      property_map.src = map_url;
    }
  }
  
  hereMapUrl(map, locations) {
    let width = map.getAttribute('width') || '270';
    let height = map.getAttribute('height') || '215';
    let params = {
      apiKey: here_api_key,
      w: width,
      h: height,
      poifc: 'red', // fill color for POI markers
      poitxs: 15,   // text font size for POI markers
      z: 16,        // max zoom
      vt: 1,        // view_type
      poithm: 1     // marker type - PIN
    };
  
    // Create a hash of markers
    let markers = locations.reduce(function(acc, location_data, index) {
      acc['poix' + index] = location_data[0] + ',' + location_data[1] + ';;;;' + location_data[2];
      return acc;
    }, {});
  
    // Add markers to the general params
    Object.assign(params, markers);
  
    // Convert the hash of params to string
    let params_str = Object.keys(params).reduce(function(str, key) {
      return str + '&' + key + '=' + params[key];
    }, '');
  
    return here_base_url + '?nocp' + params_str;
  }
  
  mapboxMapUrl(map, locations) {
    let createMapboxMarker = this.createMapboxMarker;
    const size = map.getAttribute('data-size') || '270x215';
    const position = locations.length === 1 ? [locations[0][1], locations[0][0], 15].join(',') : 'auto';
    let markers  = locations.map(function(location_data) {
      return createMapboxMarker.apply(this, location_data);
    });
    return mapbox_base_url + '/' + markers.join(',') + '/' + position + '/' + size +'@2x?access_token=' + mapbox_api_key;
  }
  
  addNewLocationToMap(locations, label, params) {
    $.get('/geocode', { address: params }, (coordinates) => {
      if(coordinates.hasOwnProperty('longitude')) {
        locations.push([coordinates.latitude, coordinates.longitude, label]);
        this.updateStaticMapUrl(locations);
        document.getElementById('address_lat').value = coordinates.latitude;
        document.getElementById('address_long').value = coordinates.longitude;
      } else {
        document.getElementById('property_map').src = this.blankMapUrl();
        document.getElementById('address_lat').value = '';
        document.getElementById('address_long').value = '';
      }
    });
  }
  
  blankMapUrl() {
    return '/assets/blank.png';
  }
  
  resetForm() {
    let address_picker = $('#address_picker');
  
    $('#address_picker .address_item:hidden').show();
    $('#address_picker .address_item input:disabled').removeAttr('disabled');
    if ($('#address_picker .address_item').length > 0) {
      address_picker.show();
    }
    $('#new_address_fields').hide();
    $('#new_address_fields input').val('');
    $('#new_address_fields select').val('');
    if(address_picker[0].dataset.available === 1) {
      $('#add_existing_address').addClass('disabled');
    }
    else {
      $('#add_existing_address').removeClass('disabled');
    }
  }
  
  initAddressPicker() {
    // Address Picker
    let address_picker = document.querySelector('#address_picker');
    address_picker.addEventListener('address.add', this.refreshMapWidget);
    address_picker.addEventListener('address.remove', this.refreshMapWidget);
    ['address.add, address.remove'].forEach(evt => {
      address_picker.addEventListener(evt, function(){
        if(address_picker.dataset.available === 1) {
          document.querySelector('#add_existing_address').classList.add('disabled');
        }
        else {
          document.querySelector('#add_existing_address').classList.remove('disabled');
        }
      });
    });
    address_picker.addEventListener('address.remove', function(){
      if(address_picker.dataset.visible === 0) {
        address_picker.style.display = 'none';
      }
    });
  
    $('#add_existing_address').bind('click', function(){
      if(!$(this).hasClass('disabled')) {
        $('#address_picker_holder').show();
        $('#available_address').show();
        address_picker.style.display = 'block';
        address_picker.dispatchEvent(new CustomEvent('address-picker:show'));
      }
  
      return false;
    });
  
    $('#add_new_address').bind('click', function(){
      $('#new_address_fields').show();
      return false;
    });

    document.querySelectorAll('#address_address, #address_city, #address_state').forEach(el => {
      el.addEventListener('change', function(){
        address_picker.dispatchEvent(new CustomEvent('address.add'));
      });
    });
  }
  
  initPropertyEssentials() {
    let essentials_form = document.querySelector('#essentials > form');
    if (essentials_form) {
      essentials_form.addEventListener('disabled', function(){
        document.querySelector('#address_picker').dispatchEvent(new CustomEvent('address-picker:reset'));
        this.refreshMapWidget();
        this.resetForm();
      });
      $('#edit-essentials').click(function(){
        essentials_form.dispatchEvent(new CustomEvent('partial-form:enable'));
      });
    }
  }

  initManagerPicker() {
    $('#manager_picker .combobox_quick_search').bind('change', function(evt, params) {
      const manager_id = $(params).data('id');
      $(evt.target).parents('.combobox_title').find('#property_property_manager_id').val(manager_id);
    });
  }

  init() {
    this.initAddressPicker();
    this.initPropertyEssentials();
    this.initManagerPicker();  
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('.edit-property-form');
  if (form) {
    const instance = new EditProperties();
    instance.init();
    document.addEventListener('edit-property-html-updated', () => {
      instance.init();
    });
  }
});

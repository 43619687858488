class ImportProviders {
  bindEvents() {
    const settings = document.querySelector('#import_settings');

    // Buildium Full Integration feature.
    // Having buildium-registration-url data attribute means
    // that the page was opened from the Buildium Marketplace
    // and the customer is trying to add Buildium API listing importer.
    if (settings.dataset.buildiumRegistrationUrl) {
      document.dispatchEvent(new CustomEvent('sm-modal:close'));
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {url: settings.dataset.buildiumRegistrationUrl }}));
    }
  }

  init() {
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const settings = document.querySelector('#import_settings');
  if (settings) {
    new ImportProviders().init();
  }
});

import MojolockBase from './mojolock_base';

class Vault extends MojolockBase {
  #price;

  constructor(account) {
    super();
    this.#price = account.codebox_unit_price / 100 + Number(DeviceUtils.getOrderPageData().juniorPriceDown);
  }

  getPrice() {
    return this.#price;
  }
}

export default Vault;
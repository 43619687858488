import DeviceUtils from '../device_utils';

class MojolockBase {
  toggleCodeboxNote() {
    DeviceUtils.hideCodeboxNumberZone();
  }

  toggleAddressBehavior() {
    DeviceUtils.showCanadian();
  }

  printFormattedPrice() {
  }
}

export default MojolockBase;
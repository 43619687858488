import 'src/stylesheets/settings/short_term_rentals/guest_messaging.scss';
import LayoutUtils from '../../utils/layout_utils';

class GuestMessagingSettings {
  constructor(wrapper) {
    this.formSelector = '#' + wrapper.id + ' form';
    this.wrapper = wrapper;

    this.templateEditors = [];

    this.hideOrRevealAll = this.hideOrRevealAll.bind(this);
    this.hideOrRevealFormContent = this.hideOrRevealFormContent.bind(this);
    this.hideOrRevealBookingConfirmationEmail = this.hideOrRevealBookingConfirmationEmail.bind(this);
    this.hideOrRevealMidStayCheckUpEmail = this.hideOrRevealMidStayCheckUpEmail.bind(this);
    this.hideOrRevealMidStayCheckUpText = this.hideOrRevealMidStayCheckUpText.bind(this);
  }

  featureEnabledCheckbox() {
    return this.form.querySelector('.js-guest-messaging-enabled');
  }

  bookingConfirmationEmailEnabledCheckbox() {
    return this.form.querySelector('.js-booking-confirmation-email-enabled');
  }

  midStayCheckUpEmailEnabledCheckbox() {
    return this.form.querySelector('.js-mid-stay-check-up-email-enabled');
  }

  midStayCheckUpTextEnabledCheckbox() {
    return this.form.querySelector('.js-mid-stay-check-up-text-enabled');
  }

  formContent() {
    return this.form.querySelectorAll('.js-guest-messaging-content');
  }

  bookingConfirmationEmailDetails() {
    return this.form.querySelectorAll('.js-booking-confirmation-email-details');
  }

  midStayCheckUpEmailDetails() {
    return this.form.querySelectorAll('.js-mid-stay-check-up-email-details');
  }

  midStayCheckUpTextDetails() {
    return this.form.querySelectorAll('.js-mid-stay-check-up-text-details');
  }

  hideOrRevealAll() {
    this.hideOrRevealFormContent();
    this.hideOrRevealBookingConfirmationEmail();
    this.hideOrRevealMidStayCheckUpEmail();
    this.hideOrRevealMidStayCheckUpText();
  }

  hideOrRevealFormContent() {
    if (this.featureEnabledCheckbox().checked) {
      LayoutUtils.show(this.formContent());
    } else {
      LayoutUtils.hide(this.formContent());
    }
  }

  hideOrRevealBookingConfirmationEmail() {
    if (this.bookingConfirmationEmailEnabledCheckbox().checked) {
      LayoutUtils.show(this.bookingConfirmationEmailDetails());
    } else {
      LayoutUtils.hide(this.bookingConfirmationEmailDetails());
    }
  }

  hideOrRevealMidStayCheckUpEmail() {
    if (this.midStayCheckUpEmailEnabledCheckbox().checked) {
      LayoutUtils.show(this.midStayCheckUpEmailDetails());
    } else {
      LayoutUtils.hide(this.midStayCheckUpEmailDetails());
    }
  }

  hideOrRevealMidStayCheckUpText() {
    if (this.midStayCheckUpTextEnabledCheckbox().checked) {
      LayoutUtils.show(this.midStayCheckUpTextDetails());
    } else {
      LayoutUtils.hide(this.midStayCheckUpTextDetails());
    }
  }

  bindSwitches() {
    this.featureEnabledCheckbox().addEventListener('change', this.hideOrRevealFormContent);
    this.bookingConfirmationEmailEnabledCheckbox().addEventListener('change', this.hideOrRevealBookingConfirmationEmail);
    this.midStayCheckUpEmailEnabledCheckbox().addEventListener('change', this.hideOrRevealMidStayCheckUpEmail);
    this.midStayCheckUpTextEnabledCheckbox().addEventListener('change', this.hideOrRevealMidStayCheckUpText);
  }

  initTemplateEditors() {
    const emailFieldIds = [
      'guest_messaging_setting_booking_confirmation_email',
      'guest_messaging_setting_reservation_reminder_email',
      'guest_messaging_setting_evening_before_arrival_email',
      'guest_messaging_setting_mid_stay_check_up_email',
      'guest_messaging_setting_morning_of_last_day_email',
      'guest_messaging_setting_guest_departure_email',
      'guest_messaging_setting_canceled_stay_email'
    ];

    const textFieldIds = [
      'guest_messaging_setting_reservation_reminder_text',
      'guest_messaging_setting_evening_before_arrival_text',
      'guest_messaging_setting_mid_stay_check_up_text',
      'guest_messaging_setting_morning_of_last_day_text',
      'guest_messaging_setting_guest_departure_text'
    ];

    emailFieldIds.forEach(fieldId => {
      let field = this.form.querySelector('#' + fieldId);
      if (field !== null) {
        this.templateEditors.push(this.addRichTemplateEditor(fieldId));
      }
    });

    textFieldIds.forEach(fieldId => {
      let field = this.form.querySelector('#' + fieldId);
      if (field !== null) {
        this.templateEditors.push(this.addRichTemplateEditor(fieldId, true));
      }
    });
  }

  addRichTemplateEditor(fieldId, compact = false) {
    var settings = {};

    if (compact == true) {
      settings.editorHeight = 125;
      settings.toolbar = [
        'placeholder',
        'restoreDefaultTemplate'
      ];
    } else {
      settings.toolbar = [
        'bold',
        'italic',
        {'list': 'bullet'}, 
        {'list': 'ordered'},
        'link',
        'insert',
        'placeholder',
        'restoreDefaultTemplate'
      ];
    }

    return new window.SMTemplateRichEditor().init(fieldId, settings);
  }

  setTemplateEditorsToEditMode() {
    this.templateEditors.forEach(el => {
      el.setEditMode();
    });
  }

  setTemplateEditorsToShowMode() {
    this.templateEditors.forEach(el => {
      el.setShowMode();
    });
  }

  resetTemplateEditors() {
    this.templateEditors.forEach(el => {
      el.resetContent();
    });
  }

  bindEvents() {
    this.bindSwitches();

    this.form.addEventListener('submitted-with-errors', () => {
      this.form.dispatchEvent(new CustomEvent('partial-form:enable'));
    });

    this.form.addEventListener('cancelled', () => {
      this.hideOrRevealAll();
      this.resetTemplateEditors();
      this.setTemplateEditorsToShowMode();
    });

    this.form.addEventListener('enabled', () => {
      this.setTemplateEditorsToEditMode();
    });
  }

  initForm() {
    this.form = this.wrapper.querySelector('form');

    this.initTemplateEditors();
    this.hideOrRevealAll();
  }

  init() {
    this.initForm();
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.getElementById('guest-messaging-settings');
  if (wrapper) {
    wrapper.addEventListener('updated', () => {
      var settingsInstance = new GuestMessagingSettings(wrapper);
      settingsInstance.init();
    });
    wrapper.dispatchEvent(new CustomEvent('updated'));
  }
});


import MojolockBase from './mojolock_base';

class MojolockPromo extends MojolockBase {
  #price;

  constructor() {
    super();
    this.#price = DeviceUtils.getOrderPageData().mojolockPromoPrice;
  }

  getPrice() {
    return this.#price;
  }

  printFormattedPrice() {
    $('strong#fixed_mojolock_price').text('$' + this.#price);
  }
}

export default MojolockPromo;
class Roommates {
  getHtml(data, questionDiv) {
    questionDiv.find('[data-binding="text"]')
      .val('Will you have any roommates?')
      .wrap('<span class="system_question"></span>')
      .after('<br><small>(Prospects can provide up to four names or email addresses.)</small>');
    questionDiv.find('[data-binding="answer_required"]').attr('disabled', 'disabled');

    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  isSingleQuestion() {
    return true;
  }
}

window.Roommates = Roommates;
class Movedate {
  getHtml({movedateQuestionText}, questionDiv) {
    questionDiv
      .find('[data-binding="text"]')
      .val('What\'s your earliest possible move-in date?')
      .wrap('<span class="system_question"></span>')
      .after(movedateQuestionText);
    
    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  isSingleQuestion() {
    return true;
  }
}

window.Movedate = Movedate;
class Income {
  getHtml({incomeOptions, inMesage, incomeAllowingQuestionMessage}, questionDiv) {
    questionDiv.find('[data-binding="text"]').wrap('<span class="system_question"></span>');
    var s= $('<select class="partialform-edit will_wont_select income_drop_down" \
      id="account_showing_income_limitation" \
      name="question[][correct_answer]" />');

    incomeOptions = incomeOptions.split(',');

    for(var val in incomeOptions) $('<option />',{value: incomeOptions[val],
      text: incomeOptions[val]}).appendTo(s);

    var after_message = `<br> <small id='income_content'>${incomeAllowingQuestionMessage}</small>`;

    questionDiv.find('[data-binding="text"]').val(inMesage).after(after_message);
    questionDiv.find('#income_content').after(s);

    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  isSingleQuestion() {
    return false;
  }
}

window.Income = Income;

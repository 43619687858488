export class ListingDefaultBase {
  #prefix;
  #settingProspectsCanSchedule;
  #settingRentalAppLink;
  #lockboxCalendarBehaviourLockbox;
  #applicationLinkPolicyCustomLink;
  #customConfirmationReplyEnabled;
  #settingCustomMessageConfirmation;
  #lockboxForBrokersEnabled;

  constructor(form, prefix) {
    this.#prefix = prefix;
    this.#settingProspectsCanSchedule = form.dataset.settingProspectsCanSchedule;
    this.#settingRentalAppLink = form.dataset.settingRentalAppLink;
    this.#lockboxCalendarBehaviourLockbox = form.dataset.lockboxCalendarBehaviourLockbox;
    this.#applicationLinkPolicyCustomLink = form.dataset.applicationLinkPolicyCustomLink;
    this.#customConfirmationReplyEnabled = form.dataset.customConfirmationReplyEnabled;
    this.#settingCustomMessageConfirmation = form.dataset.settingCustomMessageConfirmation;
    if(this.#prefix == 'listing_default_attrs') this.#lockboxForBrokersEnabled = form.dataset.lockboxForBrokersEnabled;
  }

  hideSettingSelectOptions = () => {
    const options = document.querySelectorAll(`select#${this.#prefix}_form_setting option`);
    const select = document.querySelector(`select#${this.#prefix}_form_setting`);
    options.forEach(option => {
      select.removeChild(option);
    });
  };

  showSettingSelectOptions = (category) => {
    if (category) {
      const select = document.querySelector(`select#${this.#prefix}_form_setting`);
      const options = document.querySelectorAll(`#${this.#prefix}_form_hidden_setting option.${category}`);
      options.forEach(function(option) {
        const clonedOption = option.cloneNode(true);
        select.appendChild(clonedOption);
      });
    }
  };

  refreshSettingSelectOptions = () => {
    this.hideSettingSelectOptions();
    const category = document.querySelector(`select#${this.#prefix}_form_category`).value;
    this.showSettingSelectOptions(category);
  };

  hideSettingAttrsList = () => {
    const settingAttrsList = document.querySelectorAll('li.setting-attrs');
    if(settingAttrsList) settingAttrsList.forEach(li => li.style.display = 'none');
  };

  showSettingAttrsList = (settingAttrs) => {
    if (settingAttrs) {
      settingAttrs.split(' ').forEach(function(attr) {
        const selectorLi = document.querySelector(`li.setting-attrs#${attr}`);
        if(selectorLi) selectorLi.style.display = 'block';
      });
    }
  };

  refreshSettingAttrsList = () => {
    this.hideSettingAttrsList();
    const selectedOption = document.querySelector(`select#${this.#prefix}_form_setting option:checked`);
    if (selectedOption) {
      const settingAttrs = selectedOption.dataset.attrs;
      this.showSettingAttrsList(settingAttrs);
    }
    this.refreshShowtimePickerType();
    this.refreshCustomApplicationUrl();
    this.refreshCustomConfirmationReplyText();
    if(this.#prefix == 'listing_default_attrs') this.refreshLockboxForBrokers();
  };

  refreshShowtimePickerType = () => {
    const settingValue = document.querySelector(`select#${this.#prefix}_form_setting`).value;
    const lockboxCalendarBehaviorValue = document.querySelector(`select#${this.#prefix}_form_lockbox_calendar_behavior`).value;
    const lockboxShowtimePickerType = document.querySelector('li#lockbox_showtime_picker_type');
    if (settingValue == this.#settingProspectsCanSchedule && lockboxCalendarBehaviorValue == this.#lockboxCalendarBehaviourLockbox) {
      lockboxShowtimePickerType.style.display = 'block';
    } else {
      lockboxShowtimePickerType.style.display = 'none';
    }
  };

  refreshLockboxForBrokers = () => {
    const settingValue = document.querySelector(`select#${this.#prefix}_form_setting`).value;
    const lockboxCalendarBehavior = document.querySelector(`select#${this.#prefix}_form_lockbox_calendar_behavior`);
    const lockboxForBrokersEnabled = document.querySelector('li#lockbox_for_brokers_enabled');

    if(!lockboxForBrokersEnabled) return;

    if (settingValue == this.#settingProspectsCanSchedule && lockboxCalendarBehavior.value == this.#lockboxForBrokersEnabled) {
      lockboxForBrokersEnabled.style.display = 'block';
    } else {
      lockboxForBrokersEnabled.style.display = 'none';
    }
  };

  refreshCustomApplicationUrl = () => {
    const settingValue = document.querySelector(`select#${this.#prefix}_form_setting`).value;
    const applicationLinkPolicyValue = document.querySelector(`select#${this.#prefix}_form_application_link_policy`).value;
    const customApplicationUrl = document.querySelector('li#custom_application_url');
    if (settingValue == this.#settingRentalAppLink && applicationLinkPolicyValue == this.#applicationLinkPolicyCustomLink) {
      customApplicationUrl.style.display = 'block';
    } else {
      customApplicationUrl.style.display = 'none';
    }
  };

  refreshCustomConfirmationReplyText = () => {
    const settingValue = document.querySelector(`select#${this.#prefix}_form_setting`).value;
    const customConfirmationReplyEnabledValue = document.querySelector(`select#${this.#prefix}_form_custom_confirmation_reply_enabled`).value;
    const customConfirmationReplyText = document.querySelector('li#custom_confirmation_reply_text');
    if (settingValue == this.#settingCustomMessageConfirmation && customConfirmationReplyEnabledValue == this.#customConfirmationReplyEnabled) {
      customConfirmationReplyText.style.display = 'block';
    } else {
      customConfirmationReplyText.style.display = 'none';
    }
  };

  spinnerValueAutocorrect = (event) => {
    if (event.currentTarget.dataset.onInputPrevented) return;

    var val = +event.currentTarget.value;
    var max = +event.currentTarget.max;
    var min = +event.currentTarget.min;

    event.currentTarget.value = val > max ? max : (val < min ? min : val);
  };
}

class TenantAddress {
  getHtml(data, questionDiv) {
    questionDiv.find('[data-binding="text"]')
      .val('What is the address of the place you are renting with us?')
      .wrap('<span class="system_question"></span>')
      .after('<br><small>Prospects can answer by selecting one of your listings (both on and off market).</small>');

    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  isSingleQuestion() {
    return true;
  }
}

window.TenantAddress = TenantAddress;
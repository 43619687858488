class Questionaire {
  #Q_i = 0;

  nextQuestionNumber() {
    let current_max = 0;
    $('input.sequence_input,.new_sequence_input').each(function(){
      if (!isNaN(this.value)) {
        current_max= Math.max(current_max,this.value);
      }
    });
    return  (current_max <= 0) ? 1 : current_max + 1;
  }

  getClassName(questionType) {
    return questionType.toLowerCase().split('/')
      .map(snake => snake.split('_')
        .map(substr => substr.charAt(0)
          .toUpperCase() +
          substr.slice(1))
        .join(''))
      .join('/');
  }

  createInstanceOf(C, args) {
    return new window[C](...args);
  }

  getQuestionHtml(question, data, questionDiv) {
    return question.getHtml(data, questionDiv);
  }

  removeNewQuestion(id, data){
    var question = $('#'+id);
    var question_type = question.find('[data-binding="question_type"]').val();
    $('.edit-questions-add[rel="' + question_type + '"]').show();

    question.remove();
    if ( data.questionsLimit && $('.question-item').size() < data.questionsLimit ) {
      $('.question-add-buttons-container').removeClass('hidden-important');
    }
  }

  createQuestion(question, questionType, data) {
    if($('#account_questionnaire_enabled').val() === 'false') {
      $('#account_questionnaire_enabled').val('true').trigger('change');
    }

    var questionDiv = document.createElement('div');
    questionDiv.innerHTML = document.querySelector('#question').innerHTML;
    questionDiv = $(questionDiv);
    questionDiv.attr('id' , 'q_'+this.#Q_i);
    questionDiv.attr('class' , 'new-question question-item');
    questionDiv.find('[data-binding="question_type"]').val(questionType);
    questionDiv.find('[data-binding="sequence"]').val(this.nextQuestionNumber());
    questionDiv.find('[name="question[][parent_text]"]').val('q_'+this.#Q_i); // for finding correct parent

    var toggleWrapper = questionDiv.find('.listing-groups-toggle-wrapper');
    toggleWrapper.find('input.enabled-for-listing-groups').attr('id', 'enabled_for_listing_groups_' + this.#Q_i);

    var groupsWrapper = questionDiv.find('.listing-groups-wrapper');
    groupsWrapper.attr('id', 'listing_groups_wrapper_' + this.#Q_i);
    groupsWrapper.find('.custom-chosen-choices').attr('id', 'listing_groups_chosen_' + this.#Q_i);
    groupsWrapper.find('.listing-groups-select').data('question-id', this.#Q_i);
    groupsWrapper.find('.listing-groups-included-excluded').attr('id', 'included_for_listing_groups_' + this.#Q_i);

    this.getQuestionHtml(question, data, questionDiv);

    const indx = this.#Q_i;

    questionDiv.show();
    questionDiv.find('[data-binding="delete_link"]')[0].addEventListener('click', () => this.removeNewQuestion('q_'+indx, data));
    
    this.#Q_i ++;

    return questionDiv;
  }

  cleanFieldsWithError() {
    $('.ts-wrapper').removeClass('with-error');
  }

  validateListingGroups() {
    var questionDiv;
    var questionWithMissingGroupsCount = 0;

    $('.enabled-for-listing-groups:checked').each(function() {
      questionDiv = $(this).parents('.question-item');
      var selectVal = questionDiv.find('.listing-groups-select').val();
      if (!selectVal || selectVal.length == 0) {
        questionDiv.find('.ts-wrapper').addClass('with-error');
        questionWithMissingGroupsCount = questionWithMissingGroupsCount + 1;
      }
    });

    if (questionWithMissingGroupsCount > 0) {
      var pluralQuestions;
      if (questionWithMissingGroupsCount > 1) {
        pluralQuestions = 'are ' + questionWithMissingGroupsCount + ' questions';
      } else {
        pluralQuestions = 'is ' + questionWithMissingGroupsCount + ' question';
      }

      var content =  $('#listing_groups_missing_alert_popup').html();
      content = content.replace(':plural_questions', pluralQuestions);
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {content: content}}));

      return false;
    }

    return true;
  }

  bindEvents(data) {
    document.querySelector('.js-questionnaire-form').addEventListener('submit', (e) => {
      this.cleanFieldsWithError();
      if (!this.validateListingGroups()) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    });

    $('#screening_question').off('click change');

    $('#questionnaire_holder form').bind('disable', function(){
      $('#questionnaire_holder').html('');
      $.get(data.accountQuestionnairesPath);
    });

    $('.add-questionnaire').click(function(){
      var tpl = $('#questionnaire-template form').clone(true);
      $('#questionnaire_holder').html(tpl);
      $('#questionnaire_holder form').removeClass('partialform-inited');
      document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#questionnaire_holder'}}));
      tpl[0].dispatchEvent(new CustomEvent('partial-form:enable'));
      tpl.bind('disable', function() {
        $('#questionnaire_holder').html('');
        $.get(data.accountQuestionnairesPath);
      });
  
      $('.add-questionnaire, .add-questionnaire-container').hide();
    });

    $('#screening_question').on('click', '.edit-questions-add', (e) => {
      const q = e.target;
      var quesiton_type = $(q).attr('rel');
      const questionInst = this.createInstanceOf(this.getClassName(quesiton_type), [data]);
      var allow_multiple = $(q).attr('data-multiple') === 'true';
      if (questionInst.isSingleQuestion() && !allow_multiple) {
        $(q).hide();
      }

      var question = this.createQuestion(questionInst, quesiton_type, data);
  
      $(q).parents('form').find('.questions_container').append(question);
      if ( data.questionsLimit && $('.question-item').size() >= data.questionsLimit ) {
        $('.question-add-buttons-container').addClass('hidden-important');
      }

      questionInst.init(question[0], data);
      const id = question.attr('id');
  
      var questionId = id.split('_')[1];
      this.initializeSwitches(`#${id} .switchery-checkbox#enabled_for_listing_groups_${questionId}`);
      this.bindToggleChangeHandler(`#${id} #enabled_for_listing_groups_${questionId}`);
  
      return false;
    });

    $('#screening_question').on('change', 'select#account_questionnaire_enabled', function(){
      if($(this).val() == 'true'){
        $('.questions-show').hide();
        $('div#questions-wrap').children().show();
        $('div#questions-wrap').show('slow');
      }
      else{
        $('div#questions-wrap').children().hide('slow',function(){
          $('div#questions-wrap').hide();
        });
      }
    });
  }

  initializeSwitches(selector) {
    const initializeToggleTooltip = this.initializeToggleTooltip;
    $(selector).each(function() {
      document.dispatchEvent(new CustomEvent('sm-toggle:init', {detail: {el: selector}}));
      initializeToggleTooltip($(this).siblings('span').find('small'));
    });
  }

  initializeToggleTooltip(smallEl) {
    smallEl.html('IF');

    tippy(smallEl[0], {
      allowHTML: true,
      content: '<div>Include based on listing groups</div>',
      theme: 'default'
    });
  }

  bindToggleChangeHandler(selector) {
    $(selector).change(function() {
      var checked = $(this).is(':checked');
      var groupsWrapper = $(this).parents('.question-item').find('.listing-groups-wrapper');
      var hiddenFlag = $(this).siblings('input[type="hidden"]');

      if (checked) {
        groupsWrapper.show();
        document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: `#${groupsWrapper[0].id}`}}));
        hiddenFlag.attr('disabled', 'disabled');
      } else {
        groupsWrapper.hide();
        hiddenFlag.removeAttr('disabled');
      }
    });
  }

  init(data) {
    if (!document.querySelector('.edit_questionnaire.enabled')) {
      $('.add-questionnaire, .add-questionnaire-container').show();
    } else {
      document.querySelector('#questionnaire_holder form').dispatchEvent(new CustomEvent('partial-form:enable'));
    }

    document.querySelectorAll('.js-question-item').forEach(q => {
      const questionInst = this.createInstanceOf(this.getClassName(q.dataset.type), [data]);
      questionInst.init(q, data);
    });

    this.initializeSwitches('.questions_container .switchery-checkbox');
    this.bindToggleChangeHandler('.questions_container .enabled-for-listing-groups');

    this.bindEvents(data);
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const qh = document.getElementById('questionnaire_holder');
  if (qh) {
    new Questionaire().init(qh.dataset);
  }
  
  const sq = document.querySelector('#screening_question');
  if (sq) {
    sq.addEventListener('html-changed', () => {
      const qh = document.getElementById('questionnaire_holder');
      if (qh) {
        new Questionaire().init(qh.dataset);
      }

      document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#questionnaire_holder'}}));
    });
  }
});
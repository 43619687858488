class ResidentAccessSettings {
  constructor(wrapper) {
    this.wrapper = wrapper;
  }

  bindEvents() {

  }

  initForm() {
    this.form = this.wrapper.querySelector('form');
  }

  init() {
    this.initForm();
    this.bindEvents();

    document.addEventListener('html-updated', (e) => {
      if (e.detail && e.detail.container == '#resident-access-settings') {
        this.initForm();
        this.bindEvents();
  
        if (e.detail && JSON.parse(e.detail.errors)) {
          this.form.dispatchEvent(new CustomEvent('partial-form:enable'));
        } else {
          this.form.dispatchEvent(new CustomEvent('partial-form:disable'));
        }
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const settings = document.getElementById('resident-access-settings');
  if (settings) {
    const settingsInstance = new ResidentAccessSettings(settings);
    settingsInstance.init();
  }
});

class MultiUserOtherSettings {
  constructor(container) {
    this.container = container;
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const importListingSelector = this.importListingFilterSelector;
    if (importListingSelector) {
      importListingSelector.addEventListener('change', () => this.toggleImportListingFilterSections());
      this.form.addEventListener('cancelled', () => this.toggleImportListingFilterSections());
    }

    if (this.showingSurgeSection) {
      this.showingSurgeSelector.addEventListener('change', () => this.toggleShowingSurgeOptions());
      this.form.addEventListener('cancelled', () => this.toggleShowingSurgeOptions());
    }
  }

  toggleImportListingFilterSections() {
    if (this.importListingFilterSelector.value === 'true') {
      this.importListingEnabledSection.style.display = 'block';
      this.importListingDisabledSection.style.display = 'none';
    } else {
      this.importListingEnabledSection.style.display = 'none';
      this.importListingDisabledSection.style.display = 'block';
    }
  }

  toggleShowingSurgeOptions() {
    if (this.showingSurgeSelector.value === 'true') {
      this.showingSurgeOptions.style.display = 'block';
      const surgeAgentRoleId = this.showingSurgeSection.dataset.surgeAgentRoleId;
      document.querySelectorAll('.user-role').forEach((element) => {
        if (!Array.from(element.options).some(option => option.textContent === 'Showing Surge Agent')) {
          const newOption = document.createElement('option');
          newOption.value = surgeAgentRoleId;
          newOption.textContent = 'Showing Surge Agent';
          element.appendChild(newOption);
        }
      });
    } else {
      this.showingSurgeOptions.style.display = 'none';
      document.querySelectorAll('.user-role option').forEach((option) => {
        if (option.textContent === 'Showing Surge Agent') {
          option.remove();
        }
      });
    }
  }

  get importListingFilterSelector() {
    return this.container.querySelector('#account_team_member_import_listings_filter_enabled');
  }

  get importListingEnabledSection() {
    return this.container.querySelector('#js-team_member_import_listings_filter_enabled');
  }

  get importListingDisabledSection() {
    return this.container.querySelector('#js-team_member_import_listings_filter_disabled');
  }

  get showingSurgeSection() {
    return this.container.querySelector('#js-showing-surge-settings-section');
  }

  get showingSurgeSelector() {
    return this.showingSurgeSection.querySelector('#account_showing_surge_enabled');
  }

  get showingSurgeOptions() {
    return this.showingSurgeSection.querySelector('#showing_surge_enabled');
  }

  get form() {
    return this.container.querySelector('form');
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const otherSettingsContainer = document.getElementById('multi_user_other_settings');
  if (otherSettingsContainer) {
    const settingsInstance = new MultiUserOtherSettings(otherSettingsContainer);
    otherSettingsContainer.addEventListener('updated', () => settingsInstance.init());
  }
});

import LayoutUtils from '../../utils/layout_utils';

class NoAvailabilitiesSettings {
  constructor(wrapper) {
    this.wrapper = wrapper;
  }

  showCurrentValue() {
    LayoutUtils.hide(this.form.querySelectorAll('.js-no-availability-item'));
    LayoutUtils.show(this.form.querySelector('.js-current-value'));
  }

  bindEvents() {
    LayoutUtils.showContentBySelected('.js-no-availability-item', this.form);
    this.form.addEventListener('cancelled', () => this.showCurrentValue());
  }

  initForm() {
    this.form = this.wrapper.querySelector("form");
    this.showCurrentValue();
  }

  init() {
    this.initForm();
    this.bindEvents();
  }
}

document.addEventListener("DOMContentLoaded", function() {
  const settings = document.getElementById('no-availability-settings');
  if (settings) {
    const settingsInstance = new NoAvailabilitiesSettings(settings);
    settingsInstance.init();
    settings.addEventListener('updated', () => settingsInstance.init());
  }
});

class ListingActivityReportContactsUpdates {
  bindEvents(wrapper) {
    const addBtns = document.querySelectorAll(`${wrapper} .js-listing-activity-report-contacts .add-new-field`);
    addBtns.forEach((addBtn) => {
      addBtn.addEventListener('click', function() {
        const context = addBtn.closest('.js-listing-activity-report-contacts');
  
        const uniqueIndex = Date.now().toString() + parseInt(Math.random() * 10);
        const html = context.querySelector('.js-new-field-wrapper').innerHTML.replace(/UNIQUE_INDEX/g, uniqueIndex);
        context.querySelector('.field-wrapper').insertAdjacentHTML('beforeend', html);
      });
    });
  }

  init(wrapper) {
    this.bindEvents(wrapper);
  }
}

export default ListingActivityReportContactsUpdates;
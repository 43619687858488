class DetailsForm {
  bindEvents(form) {
    const editButton = document.querySelector('#edit-details');
    if (editButton) {
      editButton.addEventListener('click', () => {
        form.dispatchEvent(new CustomEvent('partial-form:enable'));
      });
    }
  }

  init(form, withErrors) {
    this.bindEvents(form);
    if (withErrors) {
      form.dispatchEvent(new CustomEvent('partial-form:enable'));
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('#details form');
  if (form) {
    new DetailsForm().init(form, false);
  }

  document.addEventListener('details-form:updated', (e) => {
    const form = document.querySelector('#details form');
    let withErrors = false;
    if (e.detail && JSON.parse(e.detail.errors)) {
      withErrors = true;
    }
    new DetailsForm().init(form, withErrors);
  });
});


class ShowingNotificationContactsUpdates {
  init(wrapper) {
    document.querySelectorAll(`${wrapper} .js-listing-contacts`).forEach((lc) => {
      var listingId = lc.getAttribute('data-listing-id');
      var form = new ListingContactsForm(listingId, undefined, '#required-listing-updates'); // eslint-disable-line
      form.init();
    });
  }
}

export default ShowingNotificationContactsUpdates;
class SpecialAnnouncementForm {
  constructor(wrapper) {
    this.formSelector = '#' + wrapper.id + ' form';
    this.wrapper = wrapper;
    this.templateEditorId = 'account_special_announcement_text';
    this.templateEditor = null;
  }

  setTemplateEditorToEditMode() {
    if (!this.templateEditor) { return; }
    this.templateEditor.setEditMode();
  }

  setTemplateEditorToShowMode() {
    if (!this.templateEditor) { return; }
    this.templateEditor.setShowMode();
  }

  resetTemplateEditor() {
    if (!this.templateEditor) { return; }
    this.templateEditor.resetContent();
  }

  bindEvents() {
    this.form.addEventListener('submitted-with-errors', () => {
      this.form.dispatchEvent(new CustomEvent('partial-form:enable'));
    });

    this.form.addEventListener('cancelled', () => {
      this.resetTemplateEditor();
      this.setTemplateEditorToShowMode();
    });

    this.form.addEventListener('enabled', () => {
      this.setTemplateEditorToEditMode();
    });
  }

  initTemplateEditor() {
    let field = this.form.querySelector('#' + this.templateEditorId);
    if (field !== null) {
      this.templateEditor = this.addRichTemplateEditor(this.templateEditorId);
    }
  }

  addRichTemplateEditor(fieldId) {
    var settings = {toolbar: [['bold', 'italic', 'link']]};
    
    return new window.SMTemplateRichEditor().init(fieldId, settings);
  }

  initForm() {
    this.form = this.wrapper.querySelector('form');

    this.initTemplateEditor();
  }

  init() {
    this.initForm();
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.getElementById('special-announcement-settings');
  if (wrapper) {
    var settingsInstance = new SpecialAnnouncementForm(wrapper);
    settingsInstance.init();

    wrapper.addEventListener('updated', (e) => {
      var settingsInstance = new SpecialAnnouncementForm(wrapper);
      settingsInstance.init();

      if (e.detail && JSON.parse(e.detail.errors)) {
        wrapper.querySelector('form').dispatchEvent(new CustomEvent('submitted-with-errors'));
      }
    });
  }
});

class Custom {
  getHtml({answerLinesSelect}, questionDiv) {
    questionDiv.find('[data-binding="text"]')
      .wrap('<span class="system_question"></span>')
      .after(`<br><small>Prospect will be offered a text field ${JSON.parse(answerLinesSelect)} to answer the question.</small>`);

    return questionDiv;
  }

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  bindEvents() {}

  isSingleQuestion() {
    return false;
  }
}

window.Custom = Custom;
class InternalNotesForm {
  bindEvents(form) {
    document.querySelector('#edit-internal-notes').addEventListener('click', () => {
      document.querySelector('[name="internal_notes_form"]').dispatchEvent(new CustomEvent('partial-form:enable'));
    });
  }

  init(form, withErrors) {
    this.bindEvents(form);
    if (withErrors) {
      form.dispatchEvent(new CustomEvent('partial-form:enable'));
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('#internal_notes form');
  if (form) {
    new InternalNotesForm().init(form, false);
  }
});


class MultiUserPermissions {
  constructor(container) {
    this.container = container;
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.calendarEditableSelector.addEventListener('change', () => this.toggleListingAssignmentOptionVisibility());
    // Set initial visibility
    this.toggleListingAssignmentOptionVisibility();

    this.form.addEventListener('cancelled', () => this.toggleListingAssignmentOptionVisibility());
  }

  toggleListingAssignmentOptionVisibility() {
    if (this.calendarEditableSelector.value === 'true') {
      this.listingAssignmentOptionSelector.style.display = 'block';
    } else {
      this.listingAssignmentOptionSelector.style.display = 'none';
    }
  }

  get calendarEditableSelector() {
    return this.container.querySelector('#account_team_members_calendars_editable');
  }

  get listingAssignmentOptionSelector() {
    return this.container.querySelector('.js-showing-agents-listing-assignments-option');
  }

  get form() {
    return this.container.querySelector('form');
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const permissionsContainer = document.getElementById('multi_user_permissions');
  if (permissionsContainer) {
    const permissionsInstance = new MultiUserPermissions(permissionsContainer);
    permissionsContainer.addEventListener('updated', () => permissionsInstance.init());
  }
});

class ListingCarousel {
  setGalleryView() {
    const carouselView = JSON.parse(document.querySelector('.js-listing-wrap').dataset.photosCarouselView);
    if (carouselView) {
      document.querySelector('.js-listing-gallery-v1').classList.remove('hidden');
      document.querySelector('.js-listing-gallery-v2').classList.add('hidden');
    } else {
      document.querySelector('.js-listing-gallery-v2').classList.remove('hidden');
      document.querySelector('.js-listing-gallery-v1').classList.add('hidden');
    }
  }

  checkIfOpened() {
    const wrapper = document.querySelector('.js-listing-images-container');
    if (wrapper.classList.contains('listing-gallery-opened')) {
      if (document.querySelector('.js-listing-gallery-v1').classList.contains('hidden')) {
        this.openFullscreenGallery();
      } else {
        wrapper.classList.add('fullscreen');
        document.querySelector('body').classList.add('fullscreen');
      }
    }
  }

  openFullscreenGallery() {
    const popupContent = document.querySelector('.js-more-links-popup-content');
    if (popupContent.querySelector('.images-group')) {
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
        content: popupContent.innerHTML,
        fullscreen: true,
        classes: 'photos-gallery-modal'
      }}));
    } 
  }

  bindEvents() {
    const moreLink = document.querySelector('.js-listing-gallery-more-link');
    if (moreLink) {
      moreLink.addEventListener('click', () => {
        this.openFullscreenGallery();
      });
    }
  }

  checkIsTouchDevice() {
    if ('ontouchstart' in document.documentElement) {
      document.body.classList.add('touch-screen');
    }
  }

  init(){
    this.checkIsTouchDevice();
    this.bindEvents();
    this.setGalleryView();
    this.checkIfOpened();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const listingGalleryV2 = document.querySelector('.js-listing-gallery-v2');
  if (listingGalleryV2) {
    new ListingCarousel().init();
  }
});

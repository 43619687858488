import MojolockBase from './mojolock_base';

class MojolockG4MojoknobBlack extends MojolockBase {
  #price;

  constructor(account) {
    super();
    this.#price = account.mojolock_g4_and_mojoknob_black_unit_price / 100;
  }

  getPrice() {
    return this.#price;
  }
}

export default MojolockG4MojoknobBlack;
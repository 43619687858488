class LeadDistribution {
  init(container) {
    this.container = container;
    this.editing = false;
    this.userLimit = +this.form.dataset.userLimit;
    this.calendarDistributionFlagEnabled = this.form.dataset.calendarDistribution === 'true';
    this.distributionType = this.form.dataset.distributionType;
    this.bindEvents();
  }

  bindEvents() {
    this.container.querySelector('#edit-lead-distribution').addEventListener('click', this.handleFormEdit.bind(this));
    this.container.querySelector('#edit-lead-distribution-cancel').addEventListener('click', this.handleFormCancel.bind(this));
    this.container.querySelectorAll('.lead-distribution-add').forEach(addBtn => {
      addBtn.addEventListener('click', this.addEmptyTeamMember.bind(this));
    });
    this.container.querySelectorAll('.team-member-container .delete_link').forEach(deleteLink => {
      deleteLink.addEventListener('click', this.removeTeamMember.bind(this));
    });
    this.container.querySelectorAll('.team-member-container .send_login_details').forEach(sendLoginDetailsLink => {
      sendLoginDetailsLink.addEventListener('click', this.handleLoginDetailsClick.bind(this));
    });

    // Toggle sections when feature is enabled/disabled
    this.form.querySelector('#account_lead_claim_chaining_enabled').addEventListener('change', (e) => {
      this.form.querySelector('#lead-claim-chaining-details').style.display = e.target.value === 'true' ? 'block' : 'none';
    });

    this.form.querySelector('#account_showing_aggregation_enabled').addEventListener('change', (e) => {
      this.form.querySelector('#showing_aggregation_enabled').style.display = e.target.value === 'true' ? 'block' : 'none';
    });

    this.container.querySelectorAll('.lead-distribution-container .user-role').forEach(userRole => {
      userRole.addEventListener('change', this.handleUserRoleChange.bind(this));
      userRole.dispatchEvent(new Event('change'));
    });

    this.container.querySelectorAll('.switch_out').forEach(switchOutLink => {
      switchOutLink.addEventListener('click', this.handleSwitchOutClick.bind(this));
    });
  }

  handleFormEdit(e) {
    if (e.target.classList.contains('disabled')) {
      e.preventDefault();
      return false;
    }

    // Disable team type form edit
    document.getElementById('team-type-form').reset();
    document.querySelector('div.distribution-type-show').classList.remove('hidden');
    document.querySelector('div.distribution-type-edit').classList.add('hidden');

    this.editing = true;
    this.container.querySelectorAll('.lead-distribution-edit').forEach(el => {
      el.style.display = 'inline-block';
    });
    this.container.querySelectorAll('.lead-distribution-show').forEach(el => {
      el.style.display = 'none';
    });
    this.toggleAddTeamMemberButton();
  }

  handleFormCancel() {
    this.editing = false;
    this.container.querySelector('.new-team-member')?.remove();
    this.form.reset();
    this.container.querySelectorAll('.lead-distribution-edit').forEach(el => {
      el.style.display = 'none';
    });
    this.container.querySelectorAll('.lead-distribution-show').forEach(el => {
      el.style.display = 'inline-block';
    });
    this.toggleAddTeamMemberButton();
  }

  addEmptyTeamMember(e) {
    const teamMemberType = e.target.dataset.memberType;
    const teamMember = this.container.querySelector('#js-blank-team-member').content.querySelector('li').cloneNode(true);

    // Append hidden input for member type
    const memberTypeInput = document.createElement('input');
    memberTypeInput.type = 'hidden';
    memberTypeInput.name = 'team_member[][member_type]';
    memberTypeInput.value = teamMemberType;
    teamMember.querySelector('.team-member-container').appendChild(memberTypeInput);

    teamMember.classList.add('new-team-member');
    teamMember.querySelector('.delete_link').addEventListener('click', this.removeTeamMember.bind(this));

    const userRole = teamMember.querySelector('.user-role');
    userRole.addEventListener('change', this.handleUserRoleChange.bind(this));
    userRole.dispatchEvent(new Event('change'));

    if (this.distributionType !== 'CALENDAR') {
      if (teamMember.querySelector('.team_member_login_details')) {
        teamMember.querySelector('.team_member_login_details').style.display = 'none';
      }
      if (teamMember.querySelector('.lead_distribution_role')) {
        teamMember.querySelector('.lead_distribution_role').style.display = 'none';
      }
    }

    const leadDistributionPhone = teamMember.querySelector('.lead_distribution_phone');
    if (this.distributionType === 'ALL' && leadDistributionPhone) {
      leadDistributionPhone.style.display = 'none';
    }

    const type = teamMember.querySelector('.team_member_notification_type');
    if (this.distributionType !== 'CLAIM' && type) {
      type.style.display = 'none';
    }

    document.querySelector(`#lead-distribution-container-${teamMemberType}`).appendChild(teamMember);

    this.toggleAddTeamMemberButton();
  }

  removeTeamMember(e) {
    e.target.closest('.new-team-member')?.remove();
    e.target.closest('.team-member')?.remove();

    this.toggleAddTeamMemberButton();
  }

  toggleAddTeamMemberButton() {
    const numAllocatedSlots = this.form.querySelectorAll('li.team-member, li.new-team-member').length;
    const show = this.editing && (
      this.userLimit === 0 ||
      this.calendarDistributionFlagEnabled ||
      this.distributionType !== 'CALENDAR' ||
      numAllocatedSlots < this.userLimit - 1
    );
    const btnDisplay = show ? '' : 'none';
    this.container.querySelectorAll('.lead-distribution-add').forEach(btn => btn.style.display = btnDisplay);
  }

  handleLoginDetailsClick(e) {
    const link = e.target;
    const roleId = link.closest('.team-member').querySelector('.user-role').value;
    const href = link.getAttribute('href').replace(/\?.*/, '') + '?team_member[role_id]=' + roleId;
    link.setAttribute('href', href);
  }

  handleUserRoleChange(e) {
    const userRoleSelector = e.target;
    const selectedRole = userRoleSelector.options[userRoleSelector.selectedIndex].text;
    const teamMemberRow = userRoleSelector.closest('.team-member-container');
    const domainField = teamMemberRow.querySelector('.team-member-domain');

    if (!domainField) return;

    domainField.style.display = ['Showing Agent', 'Administrator'].includes(selectedRole) ? 'block' : 'none';
  }

  handleSwitchOutClick(e) {
    e.preventDefault();

    document.dispatchEvent(new CustomEvent('sm-modal:open', {
      detail: { content: document.querySelector('#multi-user-switch-out-template').innerHTML }
    }));

    const switchOutLink = e.target;
    document.querySelector('#multi-user-switch-out .errors').style.display = 'none';
    document.querySelector('#multi-user-switch-out .team-member-name').innerText = switchOutLink.dataset.name;

    const form = document.querySelector('#multi-user-switch-out form');
    form.setAttribute('action', form.dataset.link.replace('team_member_id', switchOutLink.dataset.id));
  }

  get form() {
    return this.container.querySelector('form');
  }
}

const initLeadDistributionForm = () => {
  const leadDistributionContainer = document.getElementById('multi_user_team_members');
  if (leadDistributionContainer) {
    const leadDistributionInstance = new LeadDistribution();
    leadDistributionInstance.init(leadDistributionContainer);
    leadDistributionContainer.addEventListener('updated', () => leadDistributionInstance.init(leadDistributionContainer));
  }
};

document.addEventListener('DOMContentLoaded', initLeadDistributionForm);
document.addEventListener('LeadDistributionFormLoaded', initLeadDistributionForm);

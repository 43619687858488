class TeamMemberEmailAlerts {
  init() {
    let checkedEmailAlerts = [];
    let emailAlertCheckboxes = document.querySelectorAll('form#team-member-email-alerts input[type=checkbox]');

    document.querySelectorAll('form#team-member-email-alerts input[type=checkbox]:checked').forEach(function(el) {
      checkedEmailAlerts.push([...emailAlertCheckboxes].indexOf(el));
    });

    function isArraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;

      return arr1.every(node => arr2.includes(node));
    }

    emailAlertCheckboxes.forEach((el) => {
      el.addEventListener('change', function() {
        let changedCheckboxes = [];
        document.querySelectorAll('form#team-member-email-alerts input[type=checkbox]:checked').forEach(function(el) {
          changedCheckboxes.push([...emailAlertCheckboxes].indexOf(el));
        });

        if (isArraysEqual(changedCheckboxes, checkedEmailAlerts)) {
          LayoutUtils.hide(document.querySelector('form#team-member-email-alerts .control'));
        } else {
          LayoutUtils.show(document.querySelector('form#team-member-email-alerts .control'));
        }
      });
    });

    document.querySelector('form#team-member-email-alerts .control a.button.cancel').addEventListener('click', function() {
      document.querySelectorAll('#team-member-email-alerts input[type="checkbox"]').forEach(function(item) {
        const index = [...emailAlertCheckboxes].indexOf(item);
        const isChecked = [...checkedEmailAlerts].indexOf(index) > -1;
        item.dispatchEvent(new CustomEvent('sm-toggle:set-state', {detail: {'value': isChecked}}));
      });

      LayoutUtils.hide(document.querySelectorAll('form#team-member-email-alerts .control'));
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  if (document.querySelector('#tm-email-alerts')) {
    new TeamMemberEmailAlerts().init();

    document.addEventListener('html-updated', (e) => {
      if (e.detail?.container == '#tm-email-alerts') {
        new TeamMemberEmailAlerts().init();
      }
    });
  }
});

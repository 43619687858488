class SelfShowings {
  #lockboxCalendarBehaviourAccompanied;
  #lockboxCalendarBehaviorBroker;
  #lockboxAccompaniedMessage;
  #lockboxCalendarBehaviourLockbox;
  #lockboxMessage;
  #lockboxRequiresProspectChoice;
  #lockboxCalendarBehavior;

  setMessageField(select, newVal) {
    const msgField = select.closest('form').querySelector('.note_msg');
    if (msgField) {
      var message = '';
      if (newVal == this.#lockboxCalendarBehavior) {
        msgField.textContent = '';
        return;
      } else if (newVal == this.#lockboxCalendarBehaviourAccompanied || newVal == this.#lockboxCalendarBehaviorBroker) {
        message = this.#lockboxAccompaniedMessage;
      } else if (newVal == this.#lockboxCalendarBehaviourLockbox) {
        message = this.#lockboxMessage;
      }
      msgField.innerHTML = message;
    }
  }

  bindEvents() {
    const listingLockboxCalendarBehavior = document.querySelector('select.listing_lockbox_calendar_behavior');

    listingLockboxCalendarBehavior.addEventListener('change', e => {
      const select = e.currentTarget;
      const newVal = select.value;
      this.setMessageField(select, newVal);

      $('select.listing_lockbox_calendar_behavior').not($(select)).val(newVal);
      if (newVal == this.#lockboxCalendarBehaviourAccompanied) {
        $('.listing_lockbox_calendar_behavior_accompanied_text').show();
        $('.listing_lockbox_calendar_behavior_lockbox_text').hide();
        $('.listing_lockbox_calendar_behavior_broker_text').show();
        $('.listing_lockbox_calendar_behavior_broker_switch').hide();
        $('.required_question_for_lockbox').show();
        $('.js-phone-call-verification').show();
      } else if (newVal == this.#lockboxCalendarBehaviourLockbox) {
        $('.listing_lockbox_calendar_behavior_accompanied_text').hide();
        $('.listing_lockbox_calendar_behavior_lockbox_text').show();
        $('.listing_lockbox_calendar_behavior_broker_text').show();
        $('.listing_lockbox_calendar_behavior_broker_switch').hide();
        $('.required_question_for_lockbox').show();
        $('.js-phone-call-verification').show();
      } else {
        $('.listing_lockbox_calendar_behavior_accompanied_text').hide();
        $('.listing_lockbox_calendar_behavior_lockbox_text').hide();
        $('.listing_lockbox_calendar_behavior_broker_text').hide();
        $('.listing_lockbox_calendar_behavior_broker_switch').show();
        $('.required_question_for_lockbox').hide();
        $('.js-phone-call-verification').hide();
      }
    });

    document.querySelector('.listing_required_question_for_lockbox').addEventListener('change', e => {
      const select = e.currentTarget;
      const new_val = select.value;
      const $textField = $('.listing_lockbox_additional_screening_question_prospect_choice_text');
      $('select.listing_required_question_for_lockbox').not($(select)).val(new_val);
      if (new_val == this.#lockboxRequiresProspectChoice) {
        $textField.show();
      } else {
        $textField.hide();
      }
    });
  }

  init(form) {
    this.#lockboxCalendarBehaviourAccompanied = form.dataset.lockboxCalendarBehaviourAccompanied;
    this.#lockboxCalendarBehaviorBroker = form.dataset.lockboxCalendarBehaviorBroker;
    this.#lockboxAccompaniedMessage = form.dataset.lockboxAccompaniedMessage;
    this.#lockboxCalendarBehaviourLockbox = form.dataset.lockboxCalendarBehaviourLockbox;
    this.#lockboxMessage = form.dataset.lockboxMessage;
    this.#lockboxRequiresProspectChoice = form.dataset.lockboxRequiresProspectChoice;
    this.#lockboxCalendarBehavior = form.dataset.lockboxCalendarBehavior;

    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('.js-self-showings-form');
  if (form) {
    const selfShowings = new SelfShowings();
    selfShowings.init(form);
    document.addEventListener('html-updated', (e) => {
      if (e.detail && e.detail.container == '#lockbox_defaults_settings') {
        selfShowings.init(document.querySelector('.js-self-showings-form'));
      }
    });
  }
});

import FetchUtils from 'src/utils/fetch_utils';
import LayoutUtils from 'src/utils/layout_utils';

class AccountGeneralSettings {
  constructor(wrapper) {
    this.wrapper = wrapper;
  }

  switchToggle(e) {
    LayoutUtils.startLoader(this.form);

    const param = {};
    param[e.target.name] = e.target.value;

    fetch(this.form.action, {
      method: 'PUT',
      body: new URLSearchParams(new FormData(this.form))
    }).then(FetchUtils.checkResponseStatus).then((resp) => {
      return resp.json();
    }).then(() => {
      LayoutUtils.stopLoader(this.form);
    }).catch(() => {
      LayoutUtils.stopLoader(this.form);
    });
  }

  bindEvents() {
    this.form.querySelector('.switchery-checkbox').addEventListener('change', e => this.switchToggle(e));
  }

  initForm() {
    this.form = this.wrapper.querySelector('form');
  }

  init() {
    this.initForm();
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const settings = document.getElementById('manufactured-home-enabled-section');
  if (settings) {
    const settingsInstance = new AccountGeneralSettings(settings);
    settingsInstance.init();
    settings.addEventListener('updated', () => settingsInstance.init());
  }
});

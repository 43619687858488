import MojolockBase from './mojolock_base';

class MojoknobSilver extends MojolockBase {
  #price;
  
  getPrice() {
    return this.#price;
  }

  constructor(account) {
    super();
    this.#price = account.mojoknob_silver_unit_price / 100;
  }
}

export default MojoknobSilver;
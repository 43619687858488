class ListingOptions {
  toggle_text(el){
    if($('select.' + el + ' :selected').val() == 'true'){
      $('input.' + el ).attr('value','true');
      $('.enabled_text.'+ el).show('slow');
      $('div#listing-options-edit').css('height','80px');
    }
    else {
      $('input.' + el ).attr('value','false');
      $('.enabled_text.' + el).hide('slow');
      $('div#listing-options-edit').removeAttr('style');
    }
  }

  check_accept_type() {
    if($('select#listing_accept_type').val() == 'ACCEPT_TYPE_JUST_ME'){
      $('#showing-acceptance-contacts').hide();
    }
  }

  bindEvents() {
    $(document).on('change', 'select#listing_masking_enable', function() {
      if ($(this).val() == 'false') {
        let address = $('input#listing_address').val();
        let city = $('input#listing_city').val();
        let state = $('select#listing_state').val();
        if ($('input#listing_address').val() == '') {
          //$('._right').hide('slow');
        }
        else {
          AddressMapUtils.updateMapboxMapLink('div#essentials', address, city, state);
          //$('._right').show('slow');
        }
      }
      else {
        AddressMapUtils.checkMap('listing_options');
        if ($('input#listing_masking_street_1').val() == '' || $('input#listing_masking_street_2').val() == ''){
          return false;
        }
        let address = $('input#listing_masking_street_1').val() + ' @ ' + $('input#listing_masking_street_2').val();
        let city = $('input#listing_city').val();
        let state = $('select#listing_state').val();
        AddressMapUtils.updateMapboxMapLink('div#listing_options', address, city, state);
      }
    });

    $(document).on('change', 'input#listing_masking_street_1, input#listing_masking_street_2', function() {
      if ($('input#listing_masking_street_1').val() == '' ||  $('input#listing_masking_street_2').val() == '') {
        return false;
      }
      let address = $('input#listing_masking_street_1').val() + ' @ ' + $('input#listing_masking_street_2').val();
      let city = $('input#listing_city').val();
      let state = $('select#listing_state').val();
      AddressMapUtils.updateMapboxMapLink('div#listing_options', address, city, state);
      AddressMapUtils.checkMap('listing_options');
    });

    const toggle_text = this.toggle_text;

    $(document).on('change', 'select.listing_masking_enable,select.listing_secondary_listings_enable,select.listing_secondary_listings_enable,select.listing_custom_showing_details_enabled,select.listing_custom_confirmation_reply_enabled', function() {
      toggle_text($(this).attr('class'));
    });

    $('#listing_options form').on('cancelled', () =>  {
      toggle_text('listing_masking_enable');
      toggle_text('listing_custom_showing_details_enabled');
      toggle_text('listing_custom_confirmation_reply_enabled');
      $('#listing_block_scheduled_showings').trigger('change');
      this.check_accept_type();
    });
  }

  init() {
    this.bindEvents();

    $('#edit-listing-options').click(function(){
      document.querySelector('#listing_options form').dispatchEvent(new CustomEvent('partial-form:enable'));
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrap = document.querySelector('#listing_options');
  if (wrap) {
    new ListingOptions().init();
  }
});
class CustomDate {
  getHtml(data, questionDiv) {
    questionDiv.find('[data-binding="text"]')
      .val('').wrap('<span class="system_question"></span>')
      .after('<br><small>(Prospects can answer by selecting a specific date)</small>');
    
    return questionDiv;
  }

  bindEvents() {}
  
  init(wrapper) {
    this.bindEvents(wrapper);
  }

  isSingleQuestion() {
    return false;
  }
}

window.CustomDate = CustomDate;
import MojolockBase from './mojolock_base';

class MojoknobBlack extends MojolockBase  {
  #price;

  constructor(account) {
    super();
    this.#price = account.mojoknob_black_unit_price / 100;
  }

  getPrice() {
    return this.#price;
  }
}

export default MojoknobBlack;
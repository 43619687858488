class CompanyLogo {
  constructor(wrapper) {
    this.form = wrapper.querySelector('form');
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const fileInput = this.form.querySelector('#branding_setting_company_logo');
    this.form.querySelector('#js-logo-upload-button').addEventListener('click', (e) => {
      e.preventDefault();
      fileInput.focus();
      fileInput.click();
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.getElementById('company-logo');
  if (wrapper) {
    wrapper.addEventListener('updated', () => {
      const formInstance = new CompanyLogo(wrapper);
      formInstance.init();
    });
    wrapper.dispatchEvent(new CustomEvent('updated'));
  }
});

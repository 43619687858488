import {ListingDefaultBase} from '../listing_default_base';

const prefix = 'listings_bulk_update';

class BulkUpdate extends ListingDefaultBase {
  hideConfirmBox = () => {
    document.querySelector('#bulk_update_form #confirm_box').style.display = 'none';
    const bulkUpdateButton = document.querySelector('#bulk_update_form #bulk_update_button');
    if (bulkUpdateButton.classList.contains('disabled')) {
      bulkUpdateButton.classList.remove('disabled');
    }
  };

  showConfirmBox = (event) => {
    document.querySelector('#bulk_update_form #confirm_box').style.display = 'block';
    event.currentTarget.classList.add('disabled');
  };

  bindEvents = () => {
    document.querySelector('#bulk_update_button a.btn-primary').addEventListener('click', this.showConfirmBox);

    document.querySelector('select#listings_bulk_update_form_category').addEventListener('change', () => {
      this.refreshSettingSelectOptions();
      document.querySelector('select#listings_bulk_update_form_setting').value = '';
      this.hideSettingAttrsList();
    });

    document.querySelector('select#listings_bulk_update_form_setting').addEventListener('change', this.refreshSettingAttrsList);

    document.querySelector('#bulk_update_proceed').addEventListener('click', (event) => {
      if (event.currentTarget.getAttribute('disabled')) return;

      Rails.fire(event.currentTarget.closest('form#bulk_update_form'), 'submit');
    });

    document.querySelector('#bulk_update_cancel').addEventListener('click', () => {
      Rails.fire(document.querySelector('#bulk_update_modal .js-modal-close-trigger'), 'click');
    });

    document.querySelector('select#listings_bulk_update_form_lockbox_calendar_behavior').addEventListener('change', () => {
      this.refreshShowtimePickerType();
    });

    document.querySelector('select#listings_bulk_update_form_application_link_policy').addEventListener('change', this.refreshCustomApplicationUrl);
    document.querySelector('select#listings_bulk_update_form_custom_confirmation_reply_enabled').addEventListener('change', this.refreshCustomConfirmationReplyText);

    document.querySelectorAll('.showing-acceptance-timing').forEach(option => option.addEventListener('change', this.spinnerValueAutocorrect));
  };

  init = () => {
    this.bindEvents();
    this.hideConfirmBox();
    this.refreshSettingSelectOptions();
    this.refreshSettingAttrsList();
  };
}

function initBulkUpdate() {
  const form = document.querySelector('form#bulk_update_form');
  if (form) {
    const bulkUpdate = new BulkUpdate(form, prefix);
    bulkUpdate.init();
    document.addEventListener('bulk-update:reloaded', () => {bulkUpdate.init();});
  }
}

document.addEventListener('sm-modal:opened', () => {
  initBulkUpdate();
});

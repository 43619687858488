class QuestionnaireUtils {
  static eraseAllAdditionalQuestion(parent_id){
    const url = '/accounts/' + parent_id + '/questions/' + parent_id + '/destroy_child';
    $.ajax({url: url, type: 'DELETE'});
  }

  static eraseAdditionalQuestion(id){
    const url = '/accounts/' + id + '/questions/' + id;
    $.ajax({url: url, type: 'DELETE'});
  }
}

export default QuestionnaireUtils;
import QuestionnaireUtils from '../utils';

class Bedrooms {
  getHtml({newScreeningQuestionsEnabled}, questionDiv) {
    questionDiv.find('[data-binding="text"]')
      .val('How many bedrooms are you looking for?')
      .wrap('<span class="system_question"></span>')
      .after('<br><small>(Prospects can select from a studio to 5+ bedrooms)</small>');
    if (JSON.parse(newScreeningQuestionsEnabled)) {
      questionDiv.find('a[data-binding="delete_link"]')
        .after('<br/><span class="custom_budget_question"> <small> <input type="checkbox" name="budget"/> Show only bedrooms options that are on-the-market and ask for the budget </small> </span>');
    }

    return questionDiv;
  }

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  createAdditionalQuestion(elem){
    var questionDiv = $('#additional_question_bedrooms').clone();
    var addit_type = 'DROPDOWN';

    const wrapper = document.querySelector('#questionnaire_holder');
    questionDiv.attr('id' , 'aq_' + wrapper.dataset.aqIndx);
    questionDiv.find('[data-binding="question_type"]').val(addit_type);
    questionDiv.find('[name="question[][childvariant]"]').val('');

    var parent_id = $(elem).find('[name="question[][id]"]').val();
    var is_child = $(elem).find('[name="question[][parent_text]"]').val();

    questionDiv.find('[name="question[][parent_id]"]').val(parent_id);
    if(is_child){
      questionDiv.find('[name="question[][is_child]"]').val(is_child);
    }

    questionDiv.find('[data-binding="text"]').val('What is your ideal budget?');

    wrapper.setAttribute('data-aq-indx',wrapper.dataset.aqIndx++);
    return questionDiv;
  }

  bindEvents(questionDiv) {
    const createAdditionalQuestion = this.createAdditionalQuestion;
    const budgetInput = questionDiv.querySelector('input[name=budget]');
    if (budgetInput) {
      budgetInput.addEventListener('change', function() {
        if($(this).is(':checked')){
          var question = createAdditionalQuestion($(this).parents('.questionbox'));
          $(this).parents('.questionbox').find('.dropdown_additional_questions').append(question);
        } else {
          $(this).parents('.questionbox').find('.dropdown_additional_questions').html('');
          var id = $(this).parents('.questionbox').find('[name="question[][id]"]').val();
          if(id.match(/[0-9]/)){
            QuestionnaireUtils.eraseAllAdditionalQuestion(id);
          }
        }
      });
    }
  }

  isSingleQuestion() {
    return true;
  }
}

window.Bedrooms = Bedrooms;
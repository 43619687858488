class PaidAddressesSettings {
  constructor(wrapper) {
    this.wrapper = wrapper;
  }

  bindEvents() {
    this.form.addEventListener('submitted-with-errors', () => {
      document.querySelector(this.form).dispatchEvent(new CustomEvent('partial-form:enable'));
    });

    // form.reset doesn't wipe the value on chosen
    // so we have to handle it by ourselves
    this.editButton.addEventListener('click', () => {
      //this.multiSelect.dispatchEvent(new CustomEvent('chosen:updated'));
    });
  }

  initForm() {
    this.form = this.wrapper.querySelector('form');
    this.multiSelect = this.form.querySelector('select');
    this.editButton = this.form.getElementsByClassName('partialform-button-edit')[0];
  }

  init() {
    this.initForm();
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const settings = document.getElementById('paid-addresses');
  if (settings) {
    const settingsInstance = new PaidAddressesSettings(settings);
    settingsInstance.init();
    settings.addEventListener('updated', () => settingsInstance.init());
  }
});

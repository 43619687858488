class Watermarking {
  bindEvents() {
    document.querySelector('#cancel_watermarking_button').addEventListener('click', function() {
      // Showing initial image & hidding preview image from popup form
      document.querySelector('.watermarking-preview-image').classList.add('hidden');
      document.querySelector('.watermarking-image').classList.remove('hidden');
  
      // Cleaning generated watermark line inputs on cancel
      document.querySelector('#watermarking_lines_setup').innerHTML = '';
    });
  
    document.querySelector('#account_watermarking_policy').addEventListener('change', function(e) {
      const watermarkingDisabled = document.querySelector('.js-watermarking-settings').dataset.watermarkingDisabled;
      const options = document.querySelector('#watermarking-settings-options');
      if(e.target.value === watermarkingDisabled) {
        options.classList.add('hidden');
      } else {
        options.classList.remove('hidden');
      }
    });

    document.querySelector('#show_watermark_settings_popup').addEventListener('click', (e) => {
      e.preventDefault();
  
      this.displayPopup();
    });
  }

  displayPopup() {
    document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
      content: document.querySelector('.js-watermarking-form-template').innerHTML,
      onComplete: function() {
        loadWatermarkingLogic('watermarking_form_popup');

        $('#watermarking-form-popup').on('keyup keypress', function(e) {
          var keyCode = e.keyCode || e.which;
          if (keyCode === 13) {
            e.preventDefault();
            return false;
          }
        });
      }
    }}));
  }

  init() {
    this.bindEvents();

    document.addEventListener('html-updated', (e) => {
      if (e.detail && e.detail.container === '#watermarking-settings') {
        this.bindEvents();
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const settings = document.querySelector('.js-watermarking-settings');
  if (settings) {
    new Watermarking().init();
  }
});
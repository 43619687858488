import DeviceUtils from '../device_utils';

class Codebox {
  #price;

  constructor(account) {
    this.#price = account.codebox_unit_price / 100 + Number(DeviceUtils.getOrderPageData().juniorPriceDown);
  }

  getPrice() {
    return this.#price;
  }

  printFormattedPrice() {
    $('strong#fixed_codebox_price').text('$' + this.#price);
  }

  toggleCodeboxNote() {
    DeviceUtils.showCodeboxNumberZone();
  }

  toggleAddressBehavior() {
    DeviceUtils.hideCanadian();
  }
}

export default Codebox;
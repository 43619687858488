import MojolockBase from './mojolock_base';

class MojolockG4Black extends MojolockBase {
  #price;

  constructor(account) {
    super();
    this.#price = account.mojolock_g4_black_unit_price / 100;
  }

  getPrice() {
    return this.#price;
  }

  printFormattedPrice() {
    $('strong#fixed_mojolock_price').text('$' + this.#price);
  }
}

export default MojolockG4Black;

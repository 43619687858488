class SelfGuidedTourForm {
  bindEvents(form) {
    let _this = this;
    const editButton = document.querySelector('#edit-self-guided-tour');
    if (editButton) {
      editButton.addEventListener('click', () => {
        form.dispatchEvent(new CustomEvent('partial-form:enable'));
      });
    }

    const addTrackButton = document.querySelector('#self-guided-tour-add');
    if (addTrackButton) {
      addTrackButton.addEventListener('click', () => {
        const newObjectId = new Date().getTime();
        $('.js_track_list_edit').append(window.newSelfGuidedTourTrack.replace(/index_to_replace_with_js/g, newObjectId));
        $('input#listing_self_guided_tour_tracks_attributes_' + newObjectId + '_onumber')
          .attr('value', $('div.self-guided-tour-edit .self_guided_tour_track_list ul').length);

        _this.addRemoveTrackHandler();
        document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '.self_guided_tour_track_new_record:last-child'}}));
      });
    }

    form.addEventListener('cancelled', () => {
      document.querySelectorAll('ul.self_guided_tour_track_new_record').forEach(row => { row.remove(); });
    });

    this.addRemoveTrackHandler();

    document.querySelectorAll('.audio_track').forEach(track => {
      track.addEventListener('click', (e) => {
        if (e.target.textContent === 'PLAY') {
          e.target.textContent = 'PAUSE';
        } else {
          e.target.textContent = 'PLAY';
        }
      });
    });
  }

  addRemoveTrackHandler() {
    $('.js-remove-new-track').on('click', function(e) {
      e.target.closest('ul.self_guided_tour_track_new_record').remove();
    });
  }

  init(form, withErrors) {
    this.bindEvents(form);
    if (withErrors) {
      form.dispatchEvent(new CustomEvent('partial-form:enable'));
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('#self_guided_tour form');
  if (form) {
    new SelfGuidedTourForm().init(form, false);
  }

  document.addEventListener('self-guided-tour-form:updated', (e) => {
    const form = document.querySelector('#self_guided_tour form');
    let withErrors = false;
    if (e.detail && JSON.parse(e.detail.errors)) {
      withErrors = true;
    }
    new SelfGuidedTourForm().init(form, withErrors);

    document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#self_guided_tour'}}));
  });
});


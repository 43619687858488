class SingleDayMultiListing {
  init(updated = false) {
    this.bindEvents(updated);

    document.querySelectorAll('#sdays .show_window_listings').forEach((el) => {
      this.addListingsDropdownToSingleDay(el);
    });
  }

  handleAddEventForContainer(container = document.querySelector('#update_single_days_form')) {
    container.querySelectorAll('.add_show_window').forEach((el) => {
      el.addEventListener('click', (e) => {
        var day = e.target.closest('.js-day-item');
        LayoutUtils.hide(day.querySelectorAll('.day_no_show_windows'));
        this.addSingleDayShowingWindowToNode(day.querySelector('.edit_day_switch'));
      });
    });
  }

  handleSelectListingHandlerForContainer(container = document.querySelector('#update_single_days_form')) {
    container.querySelectorAll('.add_listing_dropdown').forEach((el) => {
      el.addEventListener('change', (e) => {
        if(e.target.value != '') {
          this.chooseListingForSingleDayNode(e.target);
        }
      });
    });
  }

  handleDatepickerChangeForContainer(container = document.querySelector('#update_single_days_form')) {
    container.querySelectorAll('.datepicker').forEach((el) => {
      el.addEventListener('change', (e) => {
        let current_datepicker = e.target;

        let alreadyHave = false;
        document.querySelectorAll('#update_single_days_form .datepicker').forEach((item) => {
          if(current_datepicker != item &&
            item.value === current_datepicker.value){
            alert('You already have a single day with this date.');
            current_datepicker.value = '';
            alreadyHave = true;
            return;
          }
        });

        if (alreadyHave) return;

        var single_day = current_datepicker.closest('.js-day-item');
        var date = current_datepicker.value;
        var day_index = new Date(Date.parse(date)).getDay();
        var weekday_node = document.querySelectorAll('#update_weekdays_form .each')[day_index];
        single_day.querySelector('.edit_day_switch .show_window')?.remove();
        weekday_node.querySelectorAll('.edit_day_switch .show_window').forEach((weekday_info) => {
          const wrapper = single_day.querySelector('.edit_day_switch');
          wrapper.append(weekday_info.cloneNode(true));
          document.dispatchEvent(new CustomEvent('time-field:init', {detail: {element: wrapper.querySelectorAll('.start_time_input, .end_time_input')}}));
          this.cleanCopiedShowWindow(single_day);
          this.handleRemoveSelectedListingForContainer([...wrapper.querySelectorAll('.show_window')].at(-1));
        });
        if(!single_day.querySelector('.edit_day_switch .show_window')) {
          LayoutUtils.show(single_day.querySelector('.day_no_show_windows'));
        }
        else {
          LayoutUtils.hide(single_day.querySelector('.day_no_show_windows'));
        }
      });
    });
  }

  handleRemoveShowWindowForContainer(container = document.querySelector('#update_single_days_form')) {
    container.querySelectorAll('.remove_show_window').forEach((el) => {
      el.addEventListener('click', function() {
        var day = el.closest('.js-day-item');
        el.closest('.show_window').remove();
        if(!day.querySelector('.edit_day_switch .show_window')){
          LayoutUtils.show(day.querySelector('.day_no_show_windows'));
        }
      });
    });
  }

  handleEditEvent() {
    const button = document.querySelector('#edit-single-days');
    if (button) {
      button.replaceWith(button.cloneNode(true));
      document.querySelector('#edit-single-days').addEventListener('click', () => {
        if(document.querySelector('#edit-weekdays-submit').classList.contains('hidden')){
          this.enableSingleDayEditMode();
          return false;
        }
        else{
          alert('Please submit or cancel your current changes before editing this panel.');
          return true;
        }
      });
    }
  }

  handleCancelEvent() {
    const button = document.querySelector('#edit-single-days-cancel');
    if (button) {
      button.replaceWith(button.cloneNode(true));
      document.querySelector('#edit-single-days-cancel').addEventListener('click', () => {
        LayoutUtils.hide(document.querySelector('#update_single_days_form .calendar_time_error'));
        document.querySelector('#update_single_days_form .invalid-format')?.classList.remove('invalid-format');
        this.disableSingleDayEditMode();
        return false;
      });
    }
  }

  handleSubmitEvent() {
    const button = document.querySelector('#edit-single-days-submit');
    if (button) {
      button.replaceWith(button.cloneNode(true));
      document.querySelector('#edit-single-days-submit').addEventListener('click', (e) => {
        e.preventDefault();
        this.disableSingleDayEditMode();

        document.querySelector('#update_single_days_form .invalid-format')?.classList.remove('invalid-format');
        var valid = this.validateTimeRangesSingleDays();

        if (valid) {
          var payloadSingleDay = this.generateJsonPayloadSingleDay();
          LayoutUtils.hide(document.querySelector('#update_single_days_form .calendar_time_error'));

          if (!payloadSingleDay.outsideBusinessHours || confirm(outsideBusinessHoursAlertText)) {
            this.disableSingleDayEditMode();
            Rails.fire(document.querySelector('#update_single_days_form'), 'submit');
          } else {
            this.enableSingleDayEditMode();
            return false;
          }
        } else {
          this.enableSingleDayEditMode();
          LayoutUtils.show(document.querySelector('#update_single_days_form .calendar_time_error'));
        }

        return false;
      });
    }
  }

  onClickRemoveListing(listing) {
    listing.addEventListener('click', (e) => {
      const listings_span = e.target.closest('span');
      e.target.remove(); //remove the listing
      listings_span.querySelector('select').remove();
      listings_span.querySelector('.ts-wrapper').remove();
      this.addListingsDropdownToSingleDay(listings_span);
    });
  }

  handleRemoveSelectedListingForContainer(container = document.querySelector('#update_single_days_form')) {
    container.querySelectorAll('.selected_listing').forEach((el) => {
      this.onClickRemoveListing(el);
    });
  }

  bindEvents(updated) {
    this.handleAddEventForContainer();
    this.handleSelectListingHandlerForContainer();
    this.handleDatepickerChangeForContainer();
    this.handleRemoveShowWindowForContainer();
    if (!updated) {
      this.handleEditEvent();
      this.handleCancelEvent();
      this.handleSubmitEvent();
      document.querySelector('#add-day-button')?.addEventListener('click', () => {
        this.addNewDayToNode(document.querySelector('#update_single_days_form .editable_top_multi_cal'));
      });
    }

    document.querySelectorAll('#update_single_days_form .remove_day').forEach((el) => {
      el.addEventListener('click', function(e) {
        e.target.closest('.js-day-item').remove();
      });
    });

    this.handleRemoveSelectedListingForContainer();
  }

  cleanCopiedShowWindow(node){
    node.querySelectorAll('.edit_day_switch .add_listing_dropdown').forEach(el => el.remove());
    node.querySelectorAll('.edit_day_switch .ts-wrapper').forEach(el => el.remove());
    node.querySelectorAll('.edit_day_switch .show_window_listings').forEach((el) => {
      this.addListingsDropdownToSingleDay(el);
    });
    this.refreshSelectionMenusSingleDay();
    node.querySelectorAll('.edit_day_switch .show_window').forEach((sw) => {
      this.handleRemoveShowWindowForContainer(sw);
    });
  }

  chooseListingForSingleDayNode(node){
    var listings_span = node.closest('span');

    if (listings_span) {
      var title = listings_span.querySelector('.add_listing_dropdown').selectedOptions[0].text.trim();
      var id = node.value;
      if (id == 'all') {
        listings_span.querySelector('.selected_listing')?.remove();
      } else {
        if (listings_span.querySelectorAll('.selected_listing').length == 1) {
          if (listings_span.querySelector('.selected_listing input').value == 'all') {
            listings_span.querySelector('.selected_listing')?.remove();
          }
        }
      }

      var listing_exists = false;
      listings_span.querySelectorAll('.selected_listing input').forEach(function(el){
        if(el.value == id) { listing_exists = true; }
      });
      if(listing_exists) { return; }

      var selected_listing_html =
          ' <div class="selected_listing" title="Remove"><input type="hidden" value="'+id+'">'+
          title+
          '</div>';
      node.remove();
      listings_span.querySelector('.ts-wrapper').remove();
      listings_span.insertAdjacentHTML('beforeend', selected_listing_html);
      this.onClickRemoveListing([...listings_span.querySelectorAll('.selected_listing')].at(-1));
      this.addListingsDropdownToSingleDay(listings_span);
    }
  }

  hideNoShows() {
    var days = document.querySelectorAll('#update_single_days_form .each');
    days.forEach(function(day){
      if(day.querySelector('.edit_day_txt .show_window')) {
        LayoutUtils.hide(day.querySelectorAll('.day_no_show_windows'));
      }
    });
  }

  showNoShows() {
    var days = document.querySelectorAll('#update_single_days_form .each');
    days.forEach(function(day){
      if (!day.querySelector('.edit_day_switch .show_window')) {
        LayoutUtils.show(day.querySelectorAll('.day_no_show_windows'));
      }
    });
  }

  deleteBlankDays() {
    var dates = document.querySelectorAll('#update_single_days_form .datepicker');
    dates.forEach(function(date){
      if(date.value == '') {
        date.closest('.each').remove();
      }
    });
  }

  addNewDayToNode(node) {
    var new_day_html = '<div class="each js-day-item">\
      <span class="edit_date">\
        <a class="add_show_window" title="Add Show Window">\
        <i class="fa fa-plus-circle"></i></a>\
        <input class="datepicker" readonly="readonly">\
      </span>\
      <span class="day_no_show_windows">Not available</span>\
      <span>\
        <a class="remove_day"><i class="glyphicon icon-with-hover glyphicon-remove-circle t3"></i></a>\
      </span>\
      <span class="edit_day_switch">\
        <input type="hidden" name="single_day_id[]" value="null" />\
      </span>\
    </div>';
    node.insertAdjacentHTML('beforeend', new_day_html);
    const removeDayIcons = document.querySelectorAll('#update_single_days_form .remove_day');

    removeDayIcons[removeDayIcons.length - 1].addEventListener('click', function(e) {
      e.target.closest('.js-day-item').remove();
    });

    const days = node.querySelectorAll('.js-day-item');

    this.handleAddEventForContainer(days[days.length - 1]);
    this.handleDatepickerChangeForContainer(days[days.length - 1]);
    this.refreshSelectionMenusSingleDay();
  }

  addSingleDayShowingWindowToNode(node){
    var showwindow_html = document.querySelector('#show-window-listings-template').innerHTML;
    node.insertAdjacentHTML('beforeend', showwindow_html);
    document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#update_single_days_form'}}));
    const nodes = node.querySelectorAll('.show_window_listings');
    const lastDayNode = nodes[nodes.length - 1];
    this.addListingsDropdownToSingleDay(lastDayNode);
    this.handleSelectListingHandlerForContainer(lastDayNode);
    this.handleDatepickerChangeForContainer(lastDayNode);
    this.refreshSelectionMenusSingleDay();
    this.handleRemoveShowWindowForContainer(lastDayNode.closest('.show_window'));
  }

  addListingsDropdownToSingleDay(node){
    var listing_select_html = document.querySelector('.js-single-day-form').dataset.addListingHtml;
    node.insertAdjacentHTML('beforeend', listing_select_html);
    document.dispatchEvent(new CustomEvent('sm-rich-ui-select:init', {detail: {el: node.querySelector('.add_listing_dropdown.js-sm-rich-ui-select')}}));
    this.handleSelectListingHandlerForContainer(node);
    this.refreshSelectionMenusSingleDay();
  }

  enableSingleDayEditMode(){
    LayoutUtils.show(document.querySelectorAll('#update_single_days_form .edit_day_switch'));
    LayoutUtils.show(document.querySelectorAll('#update_single_days_form .add_show_window'));
    LayoutUtils.hide(document.querySelectorAll('#update_single_days_form .edit_day_txt'));
    LayoutUtils.show(document.querySelectorAll('#update_single_days_form .remove_day'));
    LayoutUtils.hide(document.querySelector('#edit-single-days'));
    LayoutUtils.show(document.querySelector('#edit-single-days-submit'));
    LayoutUtils.show(document.querySelector('#edit-single-days-cancel'));
    LayoutUtils.show(document.querySelectorAll('.datepicker'));
    LayoutUtils.hide(document.querySelectorAll('.single_day_label'));
    LayoutUtils.show(document.querySelector('#add-day-button'));
    this.refreshSelectionMenusSingleDay();
    this.showNoShows();
  }

  disableSingleDayEditMode(){
    LayoutUtils.hide(document.querySelectorAll('#update_single_days_form .edit_day_switch'));
    LayoutUtils.hide(document.querySelectorAll('#update_single_days_form .add_show_window'));
    LayoutUtils.show(document.querySelectorAll('#update_single_days_form .edit_day_txt'));
    LayoutUtils.hide(document.querySelectorAll('#update_single_days_form .remove_day'));
    LayoutUtils.show(document.querySelector('#edit-single-days'));
    LayoutUtils.hide(document.querySelector('#edit-single-days-submit'));
    LayoutUtils.hide(document.querySelector('#edit-single-days-cancel'));
    LayoutUtils.hide(document.querySelectorAll('.datepicker'));
    LayoutUtils.show(document.querySelectorAll('.single_day_label'));
    LayoutUtils.hide(document.querySelector('#add-day-button'));
    this.deleteBlankDays();
    this.hideNoShows();
  }

  refreshSelectionMenusSingleDay(){
    flatpickr('#update_single_days_form .datepicker', {dateFormat: 'M j, Y', minDate: 'today'});
  }

  validateTimeRangesSingleDays() {
    var valid = true;
    var showWindows = document.querySelectorAll('#update_single_days_form .edit_day_switch .show_window');

    showWindows.forEach(function(el) {
      var start = el.querySelector('.start_time_input');
      var endTime = el.querySelector('.end_time_input');

      if (!checkTimeRange(start.value, endTime.value)) {
        start.classList.add('invalid-format');
        endTime.classList.add('invalid-format');

        valid = false;
      }
    });

    return valid;
  }

  generateJsonPayloadSingleDay() {
    var payload = [];
    var outsideBusinessHours = [];
    var days = document.querySelectorAll('#update_single_days_form .each');
    days.forEach(function(day){
      var single_day_date = day.querySelector('.datepicker').value;
      var show_windows = day.querySelectorAll('.edit_day_switch .show_window');
      var show_window_payload = [];
      show_windows.forEach(function(window){
        var start = window.querySelector('.start_time_input').value;
        var end = window.querySelector('.end_time_input').value;
        var listings = window.querySelectorAll('.selected_listing input');
        var listing_ids = [];
        listings.forEach(function(el){
          listing_ids.push(el.value);
        });
        show_window_payload.push({'start' : start,
          'end' : end,
          'listing_ids' : listing_ids});

        outsideBusinessHours.push(isOutsideBusinessHours(start, end));
      });
      payload.push({'single_day_date': single_day_date, 'show_windows': show_window_payload});
    });

    document.querySelector('input[name="json_payload_single_day"]').value = JSON.stringify(payload);

    return { outsideBusinessHours: (outsideBusinessHours.indexOf(true) > -1) };
  }
}

document.addEventListener('DOMContentLoaded', function() {
  if (document.querySelector('.js-single-day-form')) {
    new SingleDayMultiListing().init();

    document.addEventListener('html-updated', (e) => {
      if (e.detail?.container == '#sdays') {
        new SingleDayMultiListing().init(true);
      }
    });
  }
});

import {ListingDefaultBase} from '../application/listing_default_base';

const prefix = 'listing_default_attrs';

class ListingDefault extends ListingDefaultBase {
  bindEvents = () => {
    document.querySelector(`select#${prefix}_form_category`).addEventListener('change', () => {
      this.refreshSettingSelectOptions();
      document.querySelector('select#listing_default_attrs_form_setting').value = '';
      this.hideSettingAttrsList();
    });

    const setting = document.querySelector(`select#${prefix}_form_setting`);
    const submitBtn = document.querySelector('#listing_default_submit');
    const lockboxCalendarBehavior = document.querySelector(`select#${prefix}_form_lockbox_calendar_behavior`);
    const applicationLinkPolicy = document.querySelector(`select#${prefix}_form_application_link_policy`);
    const customConfirmationReplyEnabled = document.querySelector(`select#${prefix}_form_custom_confirmation_reply_enabled`);
    const showingAcceptanceTiming = document.querySelectorAll('.showing-acceptance-timing');
    const notifyDaysUntil = document.querySelector(`#${prefix}_form_notify_days_until`);

    setting.addEventListener('change', this.refreshSettingAttrsList);

    submitBtn.addEventListener('click', (event) => {
      Rails.fire(event.currentTarget.closest('form#listing_default_form'), 'submit');
    });

    lockboxCalendarBehavior.addEventListener('change', () => {
      this.refreshShowtimePickerType();
      this.refreshLockboxForBrokers();
    });

    applicationLinkPolicy.addEventListener('change', this.refreshCustomApplicationUrl);
    customConfirmationReplyEnabled.addEventListener('change', this.refreshCustomConfirmationReplyText);

    showingAcceptanceTiming.forEach(option => option.addEventListener('change', this.spinnerValueAutocorrect));
    notifyDaysUntil.addEventListener('change', this.spinnerValueAutocorrect);
  };

  init = () => {
    this.bindEvents();
    this.refreshSettingSelectOptions();
    this.refreshSettingAttrsList();
  };
}

function initListingDefault() {
  var form = document.querySelector('form#listing_default_form');
  if (form) {
    var listingDefault = new ListingDefault(form, prefix);
    listingDefault.init();

    document.addEventListener('listing-default:reloaded', () => {
      if(listingDefault) listingDefault.init();
    });

    document.addEventListener('sm-modal:closed', () => { listingDefault = null; });
  }
}

document.addEventListener('sm-modal:opened', () => {
  initListingDefault();
});

import LayoutUtils from '../../../utils/layout_utils';

class DigitalAccessDeviceUpdates {
  bindEvents(wrapper) {
    const types = document.querySelectorAll(`${wrapper} .js-digital-access-device-type`);
    types.forEach(type => {
      type.addEventListener('change', (e) => {
        const lockboxCode = e.target.value;      
        const fieldsWrapper = e.target.closest('.field-wrapper');
  
        const toDisable = fieldsWrapper.querySelectorAll('.js-lockbox-id:not([data-lockbox-code="' + lockboxCode + '"])');
        LayoutUtils.hide(toDisable);
        toDisable.forEach((f) => {
          f.setAttribute('disabled', 'disabled');
        });
  
        if (lockboxCode) {
          
          const toEnable = fieldsWrapper.querySelector('.js-lockbox-id[data-lockbox-code="' + lockboxCode + '"]');
          LayoutUtils.show(toEnable);
          toEnable.removeAttribute('disabled');
        } else {
          
          // default blank select with prompt
          LayoutUtils.show(fieldsWrapper.querySelector('.js-serial-number-prompt'));
        }
      });
    });
    this.setDigitalAccessDeviceDefault(wrapper);
  }

  setDigitalAccessDeviceDefault(wrapper) {
    const types = document.querySelectorAll(`${wrapper} .js-digital-access-device-type`);
    types.forEach(type => {
      type.dispatchEvent(new Event('change'));
    });
  }

  init(wrapper) {
    this.bindEvents(wrapper);
  }
}

export default DigitalAccessDeviceUpdates;
import DeviceUtils from '../device_utils';

class CodeboxJunior {
  #price;

  constructor(account) {
    this.#price = account.codebox_unit_price / 100;
  }

  getPrice() {
    return this.#price;
  }
  
  printFormattedPrice() {
    $('strong#fixed_codebox_price').text('$' + this.#price);
  }

  toggleCodeboxNote() {
    DeviceUtils.showCodeboxNumberZone();
  }

  toggleAddressBehavior() {
    DeviceUtils.hideCanadian();
  }
}

export default CodeboxJunior;
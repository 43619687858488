class ShowingAcceptanceIndividualForm {
  init(form) {
    if (!form.dataset.inited) {
      form.dataset.inited = 'true';
    }
  }
}

class ShowingAcceptanceSection {
  toggleShowingAcceptanceDetails (el) {
    if($('select.' + el + ' :selected').val() == 'true') {
      $('input.' + el ).attr('value','true');
      $('#showtime_acceptance').show('slow');
    } else {
      $('input.' + el ).attr('value','false');
      $('#showtime_acceptance').hide('slow');
    }
  }

  bindEvents() {
    $(document).on('click', 'a#edit-showing-acceptance', function() {
      document.querySelector('#showing_acceptance form').dispatchEvent(new CustomEvent('partial-form:enable'));
    });

    $('#showing_acceptance form').on('cancelled', () => {
      this.toggleShowingAcceptanceDetails('listing_accept_enable');
    });

    $(document).on('change', 'select.listing_accept_enable', () => {
      this.toggleShowingAcceptanceDetails('listing_accept_enable');
    });

    $(document).on('change', 'select#listing_accept_type', function() {
      if($(this).val() == 'ACCEPT_TYPE_JUST_ME'){
        $('#showing-acceptance-contacts').hide('slow');
      }
      else {
        $('#showing-acceptance-contacts').show('slow');
      }
    });
  }

  init() {
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const forms = document.querySelectorAll('.js-showing-acceptance-form');
  forms.forEach(form => {
    new ShowingAcceptanceIndividualForm().init(form);
  });

  document.addEventListener('showing-acceptance:updated', () => {
    const forms = document.querySelectorAll('.js-showing-acceptance-form');
    forms.forEach(form => {
      new ShowingAcceptanceIndividualForm().init(form);
    });

    const section = document.querySelector('#showing_acceptance');
    if (section) {
      new ShowingAcceptanceSection().init();
    }

    document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#showing_acceptance'}}));
  });

  const section = document.querySelector('#showing_acceptance');
  if (section) {
    new ShowingAcceptanceSection().init();
  }
});

import FetchUtils from '../../utils/fetch_utils';

class CancelAccount {
  handleReasonsSelectsChange(primarySelect) {
    const secondaryOptions = JSON.parse(primarySelect.dataset.secondaryReasons);
    primarySelect.addEventListener('change', function(e) {
      const secondaryReasonSelect = document.querySelector('.js-modal-wrap .js-secondary-reason');
      [...secondaryReasonSelect.options].forEach(option => option.remove());

      const newSecondaryReasonSelect = secondaryReasonSelect.cloneNode();

      const secondaryOptionsForSelectedValue = secondaryOptions[e.target.value];
      if (secondaryOptionsForSelectedValue.length > 0) {
        secondaryOptionsForSelectedValue.forEach((value) => {
          const option = document.createElement('option');
          option.value = value;
          option.text = value;
          newSecondaryReasonSelect.add(option);
        });
        secondaryReasonSelect.after(newSecondaryReasonSelect);
        secondaryReasonSelect.remove();
        newSecondaryReasonSelect.classList.remove('hidden');
      } else {
        secondaryReasonSelect.classList.add('hidden');
      }
    });

    primarySelect.dispatchEvent(new Event('change'));
  }

  bindEvents() {
    document.getElementById('show_cancel_instruction').addEventListener('click', () => {
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
        content: document.getElementById('prevent_cancel').innerHTML,
        onComplete: () => {
          document.querySelector('.js-modal-wrap .js-change-billing-plan').addEventListener('click', () => {
            document.dispatchEvent(new CustomEvent('sm-modal:close'));
            document.getElementById('billing-plan-form-trigger').click();
          });

          document.querySelector('.js-modal-wrap .js-proceed-cancel').addEventListener('click', () => {
            document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
              content: document.getElementById('cancel_instruction').innerHTML,
              onComplete: () => this.cancelAccount()
            }}));
          });
        }
      }}));
    });

    document.addEventListener('sm-modal:opened', (e) => {
      const modal = e.detail.modalContainer;
      const primaryReasonSelect = modal.querySelector('.js-primary-reason');
      if (primaryReasonSelect) {
        this.handleReasonsSelectsChange(primaryReasonSelect);
      }
    });
  }

  init() {
    this.bindEvents();
  }

  cancelAccount() {
    document.querySelector('.js-modal-wrap .js-cancel-account').addEventListener('click', (e) => {
      e.preventDefault();
      const form = document.querySelector('.js-modal-wrap #cancellation-feedback-form');
      fetch(form.action, {
        method: 'POST',
        body: new URLSearchParams(new FormData(form)),
        headers: {
          'x-csrf-token': document.querySelector('meta[name=csrf-token]').getAttributeNode('content').value
        }
      }).then(FetchUtils.checkResponseStatus).then((resp) => {
        return resp.json();
      }).then((response) => {
        if (response.success) {
          window.location.href = '/';
        }
      }).catch((err) => {
        FetchUtils.handleResponseError(err);
      });

      return false;
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const cancelBtn = document.getElementById('show_cancel_instruction');
  if (cancelBtn) {
    new CancelAccount().init();
  }
});

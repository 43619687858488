import QuestionnaireUtils from '../utils';

class Dropdown {

  endsWithHut(str) {
    return !!str.match(/\^\s*$/);
  }

  lastVariantEndsWithHash(arr) {
    return !!arr[arr.length - 1].match(/\#\s*$/);
  }

  createAdditionalQuestion(specialword, elem){
    var questionDiv = $('#additional_question').clone();
    var addit_type = 'CUSTOM';

    const wrapper = document.querySelector('#questionnaire_holder');

    questionDiv.attr('id' , 'aq_' + wrapper.dataset.aqIndx);
    questionDiv.find('[data-binding="question_type"]').val(addit_type);
    questionDiv.find('[name="question[][childvariant]"]').val(specialword);

    var parent_id = $(elem).find('[name="question[][id]"]').val();
    var is_child = $(elem).find('[name="question[][parent_text]"]').val();

    questionDiv.find('[name="question[][parent_id]"]').val(parent_id);
    if(is_child){
      questionDiv.find('[name="question[][is_child]"]').val(is_child);
    }

    var text = specialword ? 'Add question that will display when the option ' + specialword +  ' is selected' : 'Add question that will display when the last option is selected';
    var input = questionDiv.find('[data-binding="text"]');
    if ($(input).next('small')) {
      $(input).next('small').remove();
    }
    input.after('<div><small>' + text + '</small></div>');
    questionDiv.show();

    wrapper.setAttribute('data-aq-indx',wrapper.dataset.aqIndx++);
    return questionDiv;
  }

  init(wrapper) {
    $(wrapper).find('.custom_dropdown_options_select option[value="simple dropdown"]:selected').each(
      function() {
        var dropdown = $(this).parent();
        
        function question_child(obj,selector) {
          return $(obj).parents('.questionbox').find(selector);
        }

        var input_box =  question_child(dropdown,'input[name="question[][variants]"]');
        //check if restricted option present
        if (/( *# *$)|( *# *;)/.test($(input_box).val())) {
          $(dropdown).find('option:eq(1)').attr('selected', true);
        }
      }
    );

    this.bindEvents(wrapper);
  }

  bindEvents(questionDiv) {
    const lastVariantEndsWithHash = this.lastVariantEndsWithHash;
    const createAdditionalQuestion = this.createAdditionalQuestion;
    const endsWithHut = this.endsWithHut;

    questionDiv.querySelector('[name="question[][custom_dropdown_type]"]').addEventListener('change', function() {
      if (this.value == 'ask more details' || this.value == 'ask more & restrict') {
        const that = this;
        var checkedvariants = $(this).parents('.questionbox').find('input[name="question[][variants]"]').val().split(';');
        var placeholder_base = $(this).parents('.questionbox').find('input[name="question[][variants]"]');
        var placeholderText;
  
        if (this.value =='ask more details') {
          placeholderText = 'To ask for more details, end the answer with ^';
        } else if (this.value == 'ask more & restrict') {
          placeholderText = 'To restrict, end the answer with #. To ask more, end with ^';
        }
        
        if ($(placeholder_base).next('small')) {
          $(placeholder_base).next('small').remove();
        }
        $(placeholder_base).after('<small>' + placeholderText + '</small>');
  
        var additionalQuestionsAlreadyAdded = $(that).parents('.questionbox').find('.dropdown_additional_questions .additional-question').length;
        if (additionalQuestionsAlreadyAdded) { return; }
  
        var count = 0;
        $.each(checkedvariants,function(e){if(endsWithHut(this)){
          count = count + 1;
          var question = createAdditionalQuestion(checkedvariants[e], $(that).parents('.questionbox'));
          $(that).parents('.questionbox').find('.dropdown_additional_questions').append(question);
        }});
        if(count == 0 && !lastVariantEndsWithHash(checkedvariants)) {
          var question = createAdditionalQuestion(checkedvariants[checkedvariants.length-1], $(that).parents('.questionbox'));
          $(this).parents('.questionbox').find('.dropdown_additional_questions').append(question);
        }
      } else {
        let question_input =  $(this).parents('.questionbox').find('input[name="question[][variants]"]');
        if ($(question_input).next('small')) {
          $(question_input).next('small').remove();
        }
        if (this.value =='restrict scheduling')
          $(question_input).after('<small>To restrict scheduling based on a specific answer, end the answer with #</small>');
        else
          $(question_input).after('<small>Add dropdown answer options -- separated by semi-colons</small>');
  
        $(this).parents('.questionbox').find('.dropdown_additional_questions').html('');
        var id = $(this).parents('.questionbox').find('[name="question[][id]"]').val();
        if(id.match(/[0-9]/)){
          QuestionnaireUtils.eraseAllAdditionalQuestion(id);
        }
      }
    });

    questionDiv.querySelector('input[name="question[][variants]"]').addEventListener('focusout', function() {
      var that = this;
      var value = $(that).parents('.questionbox').find('[name="question[][custom_dropdown_type]"]').val();

      function assign_childvar(elem, value){
        $(elem).find('[name="question[][childvariant]"]').val(value);
        $(elem).find('[name="question[][text]"]').attr('placeholder', 'Add question that will display when the option ' + value +  ' is selected.');
      }

      function full_assign(elem, value){
        assign_childvar(elem, value);
        $(elem).attr('data-text', value);
      }

      function variant_index(variants, str) {
        var result = -1;
        $.each(variants, function (e) {
          if (this.replace(/\^$/, '') == str.replace(/\^$/, '')) {
            result = e;
            return;
          }
        });
        return result;
      }

      if (value == 'ask more details' || value == 'ask more & restrict') {
        var checkedvariants = $(this).val().split(';');
        var withhut = [];
        $.each(checkedvariants,function(e) {
          if (endsWithHut(this)) {
            withhut.push(checkedvariants[e]);
          }
        });
        if (withhut.length === 0 && !lastVariantEndsWithHash(checkedvariants)) {
          withhut.push(checkedvariants[checkedvariants.length - 1]);
        }

        let persisted_questions = $(this).parents('.questionbox').find('.persisted_subquestion');
        if(persisted_questions.length == 1 && withhut.length == 1) {
          full_assign(persisted_questions[0], withhut[0]);
        }

        persisted_questions.each(function(){
          var current_var = $(this).attr('data-text'),
            index = variant_index(withhut, current_var);
          if(index == -1) {
            let id = $(this).attr('data-id');
            QuestionnaireUtils.eraseAdditionalQuestion(id);
            $(this).remove();
          } else {
            if (withhut.indexOf(current_var) == -1){
              full_assign(this, withhut[index]);
            }
            withhut.splice(index, 1);
          }
        });

        var old_questions = $(this).parents('.questionbox').find('.dropdown_additional_questions > div.new_question');

        if(old_questions.length == 1 && withhut.length == 1) {
          assign_childvar(old_questions[0], withhut[0]);
        }

        old_questions.each(function(){
          var text = $(this).find('[name="question[][childvariant]"]').val(),
            index = variant_index(withhut, text);
          if(index == -1) {
            $(this).remove();
          }
          else
          {
            if (withhut.indexOf(text) == -1) {
              assign_childvar(this, withhut[index]);
            }
            withhut.splice(index, 1);
          }
        });

        $.each(withhut,function(e){
          var question = createAdditionalQuestion(withhut[e], $(that).parents('.questionbox'));
          $(that).parents('.questionbox').find('.dropdown_additional_questions').append(question);
        });
      }
    });
  }

  getHtml({customDropdownQuestionOptionsSelect}, questionDiv) {
    questionDiv.find('[data-binding="text"]').replaceWith('<span class="system_question"><input type="text" class="text-field questionaire" name="question[][text]" value="" /><input placeholder="Add dropdown answer options -- separated by semi-colons." type="text" class="text-field questionaire" name="question[][variants]" value="" /> </span>');
    questionDiv.find('a[data-binding="delete_link"]').after(`<small class='additional_dropdown_checkbox'>${JSON.parse(customDropdownQuestionOptionsSelect)}</small>`);

    return questionDiv;
  }

  isSingleQuestion() {
    return false;
  }
}

window.Dropdown = Dropdown;
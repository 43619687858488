class Card {
  getHtml({zipName}, questionDiv) {
    questionDiv.find('[data-binding="text"]').
      val('To confirm your identity, please provide a valid credit card. (Your card will be authorized but NOT charged.)').
      wrap('<span class="system_question js-credit-card-question"></span>').
      after(`<br><small>(Prospect must provide full name, street address, ${zipName}, card number and expiration date. We share name, address and ${zipName} with you. If you need additional details on a specific prospect, please contact us.)</small>`).
      after('<input type="hidden" name="question[][answer_required]" value="true">');

    questionDiv.find('[data-binding="answer_required"]').val('true').attr('disabled', 'disabled');

    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    $(wrapper).find('.js-credit-card-question').parents('.question-item').find('[data-binding="answer_required"]').val('true').attr('disabled', 'disabled');
    this.bindEvents();
  }

  isSingleQuestion() {
    return true;
  }
}

window.Card = Card;
class TeamType {
  constructor(container) {
    this.container = container;
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.container.querySelector('#team-type-form [type="submit"]').addEventListener('click', this.handleFormSubmit.bind(this));
    this.container.querySelector('#edit-team-type').addEventListener('click', this.handleFormEdit.bind(this));
    this.container.querySelector('#cancel-team-type').addEventListener('click', this.handleFormCancel.bind(this));
  }

  handleFormEdit() {
    this.container.querySelectorAll('div.distribution-type-show').forEach(el => el.classList.add('hidden'));
    this.container.querySelectorAll('div.distribution-type-edit').forEach(el => el.classList.remove('hidden'));
    document.querySelector('a#edit-lead-distribution-cancel').dispatchEvent(new Event('click'));
  }

  handleFormSubmit(e) {
    if (!this.container.querySelector('#multi-user-change-away-confirm')) return true;

    const selectedLeadDistributionType = this.container.querySelector('input[name="account[lead_distribution_type]"]:checked')?.value;
    if (selectedLeadDistributionType === 'DISABLED' || selectedLeadDistributionType === 'ALL') {
      e.preventDefault();

      document.dispatchEvent(new CustomEvent('sm-modal:open', {
        detail: {
          content: this.container.querySelector('#multi-user-change-away-confirm').outerHTML
        }
      }));
      return false;
    }
  }

  handleFormCancel(e) {
    e.preventDefault();

    this.container.querySelectorAll('div.distribution-type-show').forEach(el => el.classList.remove('hidden'));
    this.container.querySelectorAll('div.distribution-type-edit').forEach(el => el.classList.add('hidden'));
    this.container.querySelector('#team-type-form').reset();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const teamTypeContainer = document.getElementById('notification-type');
  if (teamTypeContainer) {
    const teamTypeInstance = new TeamType(teamTypeContainer);
    teamTypeContainer.addEventListener('updated', () => teamTypeInstance.init());
  }
});

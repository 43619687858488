class DeviceUtils {
  static getViewportWidth() {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  }

  static isMobileViewport() {
    return DeviceUtils.getViewportWidth() < 710;
  }

  static showCanadian() {
    $('.canadian-true').removeClass('hidden');
    $('.canadian-true').removeAttr('disabled');
    $('.canadian-false').addClass('hidden');
    $('.canadian-false').attr('disabled', 'disabled');
  }

  static hideCanadian() {
    $('.canadian-true').addClass('hidden');
    $('.canadian-true').attr('disabled', 'disabled');
    $('.canadian-false').removeClass('hidden');
    $('.canadian-false').removeAttr('disabled');
  }

  static showCodeboxNumberZone() {
    const divCodeboxNumberZone = $('.js-codebox-number-and-zone');
    divCodeboxNumberZone.show();
  }

  static hideCodeboxNumberZone() {
    const divCodeboxNumberZone = $('.js-codebox-number-and-zone');
    divCodeboxNumberZone.hide();
  }

  static getOrderPageData() {
    const orderPage = document.querySelector('#codebox-order');
    return orderPage.dataset;
  }

  static setDescriptionLinesHeigth() {
    if (document.querySelector('.js-device-features')) {
      if (!DeviceUtils.isMobileViewport()) {
        const wrapper = document.querySelector('.js-devices:not(.hidden)');
        const firstColumn = wrapper.querySelector('.js-device-features');
        const features = firstColumn.querySelectorAll('.js-feature-description');

        features.forEach(feature => {
          // Get index of row
          const index = Array.from(features).indexOf(feature);
          //Get maximum height for current row
          let maxHeight = 0;
          wrapper.querySelectorAll('.js-device-features').forEach(featureColumn => {
            const cell = featureColumn.querySelectorAll('.js-feature-description').item(index);
            if (cell) {
              maxHeight = Math.max(maxHeight, cell.querySelector('p').offsetHeight);
            }
            
          });
          //Set height for all columns of index row equals maximum
          wrapper.querySelectorAll('.js-device-features').forEach(featureColumn => {
            const cell = featureColumn.querySelectorAll('.js-feature-description').item(index);
            if (cell) {
              cell.style.height = `${maxHeight}px`;
            }
          });
        });
      } else {
        document.querySelectorAll('.js-device-features .js-feature-description').forEach(el => {
          el.style.height = 'auto';
        });
      }
    }
  }
}

export default DeviceUtils;
class DocumentVerification {
  #prevQuestionDocumentAutoApprovalPolicyValue;

  getHtml({defaultDocumentName, documentVerificationHtml}, questionDiv) {
    var question_text = 'We require a photo of your ' + defaultDocumentName + ' to verify your identity.';
    questionDiv.find('[data-binding="text"]')
      .val(question_text)
      .wrap('<span class="system_question"></span>')
      .after(JSON.parse(documentVerificationHtml));
    questionDiv.find('[data-binding="answer_required"]').val('true').attr('disabled', 'disabled');

    // Trigger dynamic JS behavior
    questionDiv.find('select[name="question[][document_auto_approval_policy]"]').trigger('change');
    questionDiv.find('select[name="question[][document_type]"]').trigger('change');

    return questionDiv;
  }

  showDocumentAutoApprovalDialog(newValue, questionDiv) {
    var content = $('#document-auto-approval-warning').html();

    document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {content: content,
      hideCloseButton: true,
      onComplete: () => {
        $('.js-document-auto-approval-popup-cancel').click(() => {
          questionDiv.find('select[name="question[][document_auto_approval_policy]"]').val(this.#prevQuestionDocumentAutoApprovalPolicyValue);
          document.dispatchEvent(new CustomEvent('sm-modal:close'));
        });
        $('.js-document-auto-approval-popup-ok').click(() => {
          this.#prevQuestionDocumentAutoApprovalPolicyValue = newValue;
          document.dispatchEvent(new CustomEvent('sm-modal:close'));
        });
      }}}));
  }

  bindDocumentTypeChange(questionDiv, data) {
    questionDiv.on('change', 'select[name="question[][document_type]"]', function() {
      var newValue = this.value;

      var autoApprovalOptionToHideForOtherDocuments = questionDiv.
        find('select[name="question[][document_auto_approval_policy]"]').
        find(`option[value=${data.autoAproveWhenAutoVerified}]`);

      var alwaysApproveOption = questionDiv.
        find('select[name="question[][document_auto_approval_policy]"]').
        find(`option[value=${data.alwaysAutoApprovePolicy}]`);

      var alwaysApproveOptionNameForIdentityDocument = 'auto-approved even when auto-verification fails';
      var alwaysApproveOptionNameForOtherDocuments = 'auto-approved with NO computer nor human review';

      var autoApprovalInput = questionDiv.find('select[name="question[][document_auto_approval_policy]"]');

      if (newValue == data.identityDocument) {
        autoApprovalOptionToHideForOtherDocuments.show();
        // Workaround to hide/show select option for Safari
        // See https://stackoverflow.com/questions/36066953/css-hide-options-from-select-menu-on-iphone-safari
        if (($(autoApprovalOptionToHideForOtherDocuments).parent().is('span'))) {
          $(autoApprovalOptionToHideForOtherDocuments).unwrap();
        }
        alwaysApproveOption.html(alwaysApproveOptionNameForIdentityDocument);
      } else {
        autoApprovalOptionToHideForOtherDocuments.hide();
        // Workaround to hide/show select option for Safari
        if (!($(autoApprovalOptionToHideForOtherDocuments).parent().is('span'))) {
          $(autoApprovalOptionToHideForOtherDocuments).wrap('<span>');
        }
        alwaysApproveOption.html(alwaysApproveOptionNameForOtherDocuments);
        if (autoApprovalInput.val() == data.autoAproveWhenAutoVerified) {
          autoApprovalInput.val(data.neverAutoApprovePolicy);
        }
      }
    });
    questionDiv.find('select[name="question[][document_type]"]').trigger('change');
  }

  init(wrapper, data) {
    this.bindEvents(wrapper, data);
  }

  bindEvents(questionDiv, {neverAutoApprovePolicy, alwaysAutoApprovePolicy, identityDocument, autoAproveWhenAutoVerified}) {
    questionDiv = $(questionDiv);
    this.#prevQuestionDocumentAutoApprovalPolicyValue = questionDiv.find('select[name="question[][document_auto_approval_policy]"]').val();
    questionDiv.find('[data-binding="answer_required"]').val('true').attr('disabled', 'disabled');
    questionDiv[0].querySelector('select[name="question[][document_auto_approval_policy]"]').addEventListener('change', (e) => {
      var newValue = e.target.value;

      if (!this.#prevQuestionDocumentAutoApprovalPolicyValue) {
        this.#prevQuestionDocumentAutoApprovalPolicyValue = neverAutoApprovePolicy;
      }

      var documentType = questionDiv.find('select[name="question[][document_type]"]').val();

      if (newValue == alwaysAutoApprovePolicy && documentType == identityDocument) {
        this.showDocumentAutoApprovalDialog(newValue, questionDiv);
      } else {
        this.#prevQuestionDocumentAutoApprovalPolicyValue = newValue;
      }
      return true;
    });

    this.bindDocumentTypeChange(questionDiv, {neverAutoApprovePolicy, alwaysAutoApprovePolicy, identityDocument, autoAproveWhenAutoVerified});
  }

  isSingleQuestion() {
    return true;
  }
}

window.DocumentVerification = DocumentVerification;
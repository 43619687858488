import LayoutUtils from '../../utils/layout_utils';

class CalendarOptionsUtils {
  static lockTypes = {
    ONE_LISTING: 'ONE_LISTING',
    ONE_PROPERTY: 'ONE_PROPERTY',
    ONE_LOCATION: 'ONE_LOCATION',
    NONE: 'NONE'
  };

  static CALENDAR_AUTONOMOUS_SCHEDULING = 'AUTONOMOUS_SCHEDULING';
  static SW_LOCK_TYPE_SHOWTIMES = 'each_showtime';
  static SW_LOCK_TYPE_WINDOWS = 'each_window';

  static refreshCalendarTypeControls() {
    const optIndex = document.querySelector('#calendar_cal_type').selectedIndex;
    if (optIndex == 0){
      LayoutUtils.show(document.querySelectorAll('.s-show-desc'));
      LayoutUtils.hide(document.querySelectorAll('.o-show-desc'));
      LayoutUtils.hide(document.querySelectorAll('.autonomous-scheduling-desc'));
      LayoutUtils.show(document.querySelectorAll('.non-autonomous-scheduling-desc'));
      LayoutUtils.hide(document.querySelectorAll('#include_drive_times_overridden'));
      LayoutUtils.show(document.querySelectorAll('#include_drive_times_original'));
    } else if(optIndex == 1){
      LayoutUtils.show(document.querySelectorAll('.o-show-desc'));
      LayoutUtils.hide(document.querySelectorAll('.s-show-desc'));
      LayoutUtils.hide(document.querySelectorAll('.autonomous-scheduling-desc'));
      LayoutUtils.show(document.querySelectorAll('.non-autonomous-scheduling-desc'));
      LayoutUtils.hide(document.querySelectorAll('#include_drive_times_overridden'));
      LayoutUtils.show(document.querySelectorAll('#include_drive_times_original'));
    } else if(optIndex == 2){
      LayoutUtils.hide(document.querySelectorAll('.o-show-desc'));
      LayoutUtils.hide(document.querySelectorAll('.s-show-desc'));
      LayoutUtils.show(document.querySelectorAll('.autonomous-scheduling-desc'));
      LayoutUtils.hide(document.querySelectorAll('.non-autonomous-scheduling-desc'));
      LayoutUtils.show(document.querySelectorAll('#include_drive_times_overridden'));
      LayoutUtils.hide(document.querySelectorAll('#include_drive_times_original'));
      document.querySelector('#calendar_lock_type').value = CalendarOptionsUtils.SW_LOCK_TYPE_SHOWTIMES;
      const autonomousLock = document.querySelector('#autonomous_calendar_location_lock');
      if (autonomousLock) {
        autonomousLock.dispatchEvent(new Event('change'));
      }
    }

    CalendarOptionsUtils.show_hide_showing_limit();
    CalendarOptionsUtils.show_hide_lead_hours_to_stack();
    CalendarOptionsUtils.update_lead_hours_to_piggyback();
  }

  static stackValueChangeHandler(tb, value = 1) {
    if (tb) {
      if (!(Number(tb.value) >= value)) {
        tb.value = value;
      }
    } 
  }

  static update_lead_hours_to_piggyback() {
    CalendarOptionsUtils.stackValueChangeHandler(document.querySelector('#calendar_lead_hours_to_piggyback'));

    
    const maxConcurrentEl = document.querySelector('#calendar_max_concurrent');
    if (maxConcurrentEl) {
      const maxConcurrent = Number(maxConcurrentEl.value);
      const calTypeAutonomous = document.querySelector('#calendar_cal_type').value == CalendarOptionsUtils.CALENDAR_AUTONOMOUS_SCHEDULING;
      CalendarOptionsUtils.toggleElement('.lead_hours_to_piggyback', maxConcurrent > 1); 
      CalendarOptionsUtils.toggleElement('#autonomous_location_lock', calTypeAutonomous && maxConcurrent > 1, 'block');
    }
  }

  static toggleElement(selector, shown, shownStyle = 'table-row'){
    const el = document.querySelector(selector);
    if (el) {
      el.style.display = shown ? shownStyle : 'none';
    }
  }

  static show_hide_lead_hours_to_stack() {
    const no_location_lock = document.querySelector('#calendar_location_lock').value == CalendarOptionsUtils.lockTypes.NONE;
    const lock_type_windows = document.querySelector('#calendar_lock_type').value == CalendarOptionsUtils.SW_LOCK_TYPE_WINDOWS;
    const calTypeAutonomous = document.querySelector('#calendar_cal_type').value == CalendarOptionsUtils.CALENDAR_AUTONOMOUS_SCHEDULING;

    CalendarOptionsUtils.toggleElement('.showing_stacking', !no_location_lock && lock_type_windows || calTypeAutonomous);
  }

  static show_hide_showing_limit() {
    const location_lock = document.querySelector('#calendar_location_lock').value;
    const lock_type_showtimes = document.querySelector('#calendar_lock_type').value;
    const limitShowingsNum = document.querySelector('#limit_showings_num');
    const limitWindowsNum = document.querySelector('#limit_windows_num');
    if (location_lock == CalendarOptionsUtils.lockTypes.ONE_LISTING || location_lock == CalendarOptionsUtils.lockTypes.ONE_PROPERTY) {
      if (lock_type_showtimes == 'each_showtime') {
        LayoutUtils.show(limitShowingsNum);
      } else {
        LayoutUtils.hide(limitShowingsNum);
      }
      if (lock_type_showtimes == 'each_window') {
        LayoutUtils.show(limitWindowsNum);
      } else {
        LayoutUtils.hide(limitWindowsNum);
      }
    } else {
      LayoutUtils.hide(limitShowingsNum);
      LayoutUtils.hide(limitWindowsNum);
    }
  }

  static hideAllLockType() {
    LayoutUtils.hide(document.querySelectorAll('.js-lock-type'));
  }

  static changeLockLimitsOptionsText(val) {
    document.querySelectorAll('#limit_showings_num select option, #limit_showings_num strong, #limit_windows_num select option, #limit_windows_num strong').forEach((el) => {
      let text = el.innerHTML;
      text = text.replace(/{ESTATE}|listing|property/g, val);
      el.innerHTML = text;
    });
  }
}

export default CalendarOptionsUtils;
class SchedulingRequirementsSettings {
  constructor(wrapper) {
    this.wrapper = wrapper;
  }

  bindEvents() {
    const textareaSelector = this.form.querySelector('textarea');
    const errorSelector = this.form.querySelector('.error-message');
    const textLimit = textareaSelector.dataset.limit;

    this.form.addEventListener('submit', (e) => {
      const textLength = textareaSelector.value.length;
      if(textLength > textLimit){
        errorSelector.innerHTML = 'Text should not be longer than ' + textLimit + ' symbols.';
        errorSelector.style.display = 'block';
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
    });
  }

  initForm() {
    this.form = this.wrapper.querySelector('form');
  }

  init() {
    this.initForm();
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const settings = document.getElementById('scheduling-requirements-settings');
  if (settings) {
    const settingsInstance = new SchedulingRequirementsSettings(settings);
    settingsInstance.init();
    settings.addEventListener('updated', () => settingsInstance.init());
  }
});

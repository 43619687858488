import DigitalAccessDeviceUpdates from './digital_access_device';
import ApplicantsUpdates from './applicants';
import ListingActivityReportContactsUpdates from './listing_activity_report_contacts';
import ShowingNotificationContactsUpdates from './showing_notification_contacts';
import ResidentsUpdates from './residents';
import LayoutUtils from '../../../utils/layout_utils';
import RequiredListingUpdatesUtils from './utils';

class RequiredListingUpdatesForm {
  #wrapper = '#required-listing-updates';
  #allAlerts = '#required-listing-updates-alert-all';
  #myAlerts = '#required-listing-updates-alert-my';

  replaceForm(html) {
    document.querySelector(`${this.#wrapper} form`).innerHTML = html;
    this.bindInputsChange();
    this.bindSkip();
    this.bindSnooze();
    this.bindFinishLaterSubmit();
    this.setDefaults();
    this.initIndividialUpdateTypes();

    this.enableSubmitButtons();
  }

  hideAllAlerts() {
    document.querySelectorAll(this.#allAlerts).forEach(el => {
      LayoutUtils.hide(el);
    });
  }

  hideMyAlerts() {
    document.querySelectorAll(this.#myAlerts).forEach(el => {
      LayoutUtils.hide(el);
    });
  }

  enableOrDisableSkipSnoozeButtons(skippable) {
    const btn = skippable.querySelectorAll('.skip input[type=button], .snooze input[type=button]');
    const fieldsWrapper = skippable.querySelector('.field-wrapper');

    if (RequiredListingUpdatesUtils.anyNonHiddenInputsFilled(fieldsWrapper)) {
      LayoutUtils.disableButton(btn);
    } else {
      LayoutUtils.enableButton(btn);
    }
  }

  // When change is triggered for any non-hidden input,
  // we re-check if SKIP button should be enabled or disabled.
  //
  // TIP: use "js-not-submitted-input" class for inputs
  // which are not meant to be submitted (search, etc)
  // and thus should not affect SKIP button.
  bindInputsChange(container = document) {
    ['input', 'change'].forEach((event) => {
      container.querySelectorAll('.skippable input:not([type=button]):not([type=hidden]):not(.js-not-submitted-input), .skippable select, .skippable textarea').forEach((elem) => {
        elem.addEventListener(event, () => {
          const skippable = elem.closest('.skippable');
          this.enableOrDisableSkipSnoozeButtons(skippable);
        });
      });
    });
  }

  bindSkip() {
    const btns = document.querySelectorAll(`${this.#wrapper} .skip input[type=button]`);
    btns.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        if (e.target.value == 'Skip') {
          this.skip(btn);
        } else {
          this.unskip(btn);
        }
      });
    });
  }

  skip(btn) {
    const skippable = btn.closest('.skippable');
    this.hideAndDisableOnSkipSnooze(skippable);

    // Disable SNOOZE button
    skippable.querySelectorAll('.snooze input').forEach((snooze) => {
      snooze.setAttribute('disabled', 'disabled');
    });
   
    // Handle inputs for SKIP itself
    btn.closest('.skip').querySelectorAll('input:not([type=button])').forEach((b) => {
      b.removeAttribute('disabled');
    });
    
    btn.value = 'Unskip';
  }

  unskip(btn) {
    const skippable = btn.closest('.skippable');
    this.revealAndEnableOnUnskipUnsnooze(skippable);

    // Enable SNOOZE button
    skippable.querySelectorAll('.snooze input').forEach((snooze) => {
      snooze.removeAttribute('disabled');
    });

    // Handle inputs for SKIP itself
    btn.closest('.skip').querySelectorAll('input:not([type=button])').forEach((b) => {
      b.setAttribute('disabled', 'disabled');
    });

    btn.value = 'Skip';
  }

  hideAndDisableOnSkipSnooze(skippable) {
    const fieldsWrapper = skippable.querySelector('.field-wrapper');
    const fields = fieldsWrapper.querySelectorAll('input, select, textarea');
    fields.forEach(f => {
      f.setAttribute('disabled', 'disabled');
    });
    LayoutUtils.hide(fieldsWrapper);
    LayoutUtils.hide(skippable.querySelectorAll('.hide-on-skip-snooze'));
  }

  revealAndEnableOnUnskipUnsnooze(skippable) {
    const fieldsWrapper = skippable.querySelector('.field-wrapper');
    const fields = fieldsWrapper.querySelectorAll('input:not(.js-always-disabled), select:not(.js-always-disabled), textarea:not(.js-always-disabled)');
    fields.forEach((f) => {
      f.removeAttribute('disabled');
    });
    LayoutUtils.show(fieldsWrapper);
    LayoutUtils.show(skippable.querySelectorAll('.hide-on-skip-snooze'));
  }

  bindSnooze() {
    const btns = document.querySelectorAll(`${this.#wrapper} .snooze input[type=button]`);
    btns.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        if (e.target.value == 'Snoozed') {
          this.unsnooze(btn);
        } else {
          this.snooze(btn);
        }
      });
    });
  }

  snooze(btn) {
    const skippable = btn.closest('.skippable');
    this.hideAndDisableOnSkipSnooze(skippable);

    // Disable SKIP button
    const skips = skippable.querySelectorAll('.skip input');
    skips.forEach((skip) => {
      skip.setAttribute('disabled', 'disabled');
    });
    
    // Handle inputs for SNOOZE itself
    btn.closest('.snooze').querySelectorAll('input:not([type=button])').forEach((elem) => {
      elem.removeAttribute('disabled');
    });
    
    btn.value = 'Snoozed';
  }

  unsnooze(btn) {
    const skippable = btn.closest('.skippable');
    this.revealAndEnableOnUnskipUnsnooze(skippable);

    // Enable SKIP button
    const skips = skippable.querySelectorAll('.skip input');
    skips.forEach((skip) => {
      skip.removeAttribute('disabled', 'disabled');
    });

    // Handle inputs for SNOOZE itself
    var snooze = btn.closest('.snooze');
    snooze.querySelectorAll('input:not([type=button])').forEach((elem) => {
      elem.setAttribute('disabled', 'disabled');
    });

    btn.value = snooze.dataset.snoozeLabel;
  }

  // Disables items with all empty (visible) fields,
  // if "Save and Finish Later" is clicked
  bindFinishLaterSubmit() {
    const form = document.querySelector(`${this.#wrapper} form`);

    const submits = form.querySelectorAll('input[type=submit]:not([name=all_answers_required])');
    submits.forEach(submit => {
      submit.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
  
        form.querySelectorAll('.requirements').forEach((requirement) => {
          let anyUpdatesMadeForListing = false;
  
          // Disable input fields for each individual requirement (if nothing was filled)
          requirement.querySelectorAll('.field-wrapper').forEach((fieldWrapper) => {
            if (RequiredListingUpdatesUtils.anyNonHiddenInputsFilled(fieldWrapper)) {
              anyUpdatesMadeForListing = true;
            } else {
              fieldWrapper.querySelectorAll('input, select, textarea').forEach((f) => {
                f.setAttribute('disabled', 'disabled');
              });
            }
          });
  
          // Check if any SKIP buttons for the listing was used
          requirement.querySelectorAll('.skip').forEach((skip) => {
            var skipInput = skip.querySelector('input:not([type=button]):not([id="skipped_by_default"]):not([disabled])');
            if (skipInput) { // means "SKIP" button was used
              anyUpdatesMadeForListing = true;
            }
          });
  
          // Check if any SNOOZE buttons for the listing was used
          requirement.querySelectorAll('.snooze').forEach((snooze) => {
            var skipInput = snooze.querySelector('input:not([type=button]):not([id="snoozed_by_default"]):not([disabled])');
            if (skipInput) { // means "SNOOZE" button was used
              anyUpdatesMadeForListing = true;
            }
          });
  
          // Check additional (manual) updates tracking
          requirement.querySelectorAll('.js-manual-update-tracking').forEach((manualUpdateTracking) => {
            var updateInput = manualUpdateTracking.querySelector('input:not([disabled])');
            if (updateInput) {
              anyUpdatesMadeForListing = true;
            }
          });
  
          // If nothing was filled or updated + none SKIPs / SNOOZEs used,
          // let's disable all inputs for this listing
          if (!anyUpdatesMadeForListing) {
            requirement.querySelectorAll('input, select, textarea').forEach((f) => {
              f.setAttribute('disabled', 'disabled');
            });
          }
        });
  
        if (form.dataset.remote) {
          Rails.fire(form, 'submit');
        } else {
          form.submit();
        }
      });
    });
  }

  // Triggers SKIP for newly loaded form where needed
  // TODO: handle default SNOOZE
  setSkippedSnoozedDefaults() {
    document.querySelectorAll(`${this.#wrapper} .skip input[type=button]`).forEach((btn) =>{
      var skippedByDefault = btn.closest('.skip').querySelector('#skipped_by_default').value;
      if (skippedByDefault == 'true') {
        this.skip(btn);
      }
    });

    document.querySelectorAll(`${this.#wrapper} .skippable`).forEach((elem) => {
      this.enableOrDisableSkipSnoozeButtons(elem);
    });
  }

  setDefaults() {
    this.setSkippedSnoozedDefaults();
  }

  bindClosePopupButton() {
    // We don't close popup when "X" is clicked.
    // Instead we trigger "Save and Finish Later" event.
    document.querySelectorAll('.js-modal-close-trigger').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        const btn = document.querySelector(`${this.#wrapper} form input[type=submit]:not([name=all_answers_required])`);
        if (btn) {
          btn.dispatchEvent(new Event('click', {bubbles: true}));
        }
      });
    });
  }

  bindEvents() {
    this.bindInputsChange();
    this.bindSkip();
    this.bindSnooze();
    this.bindFinishLaterSubmit();
    this.bindClosePopupButton();

    document.addEventListener('lisiting-updates:rebind-input-events', (e) => {
      this.bindInputsChange(e.detail.container);
    });

    document.querySelector(this.#wrapper).addEventListener('lisiting-updates:replace-form', (e) => {
      this.replaceForm(e.detail.html);
    });

    document.querySelector(this.#wrapper).addEventListener('lisiting-updates:hide-all-alerts', () => {
      this.hideAllAlerts();
    });

    document.querySelector(this.#wrapper).addEventListener('lisiting-updates:hide-my-alerts', () => {
      this.hideMyAlerts();
    });
  }

  enableSubmitButtons() {
    document.querySelectorAll(`${this.#wrapper} form input[type=submit`).forEach(elem => {
      LayoutUtils.enableButton(elem);
    });
  }

  initIndividialUpdateTypes() {
    new DigitalAccessDeviceUpdates().init(this.#wrapper);
    new ApplicantsUpdates().init(this.#wrapper);  
    new ListingActivityReportContactsUpdates().init(this.#wrapper);
    new ShowingNotificationContactsUpdates().init(this.#wrapper);
    new ResidentsUpdates().init(this.#wrapper);
  }

  init() {
    if (document.querySelector(`${this.#wrapper} form`)) {
      this.setDefaults();
      this.initIndividialUpdateTypes();
  
      this.bindEvents();
      this.enableSubmitButtons();
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.querySelector('#required-listing-updates');
  if (wrapper) {
    new RequiredListingUpdatesForm().init();
  }

  document.addEventListener('sm-modal:opened', (e) => {
    if (e.detail && e.detail.modalContainer) {
      if(e.detail.modalContainer.querySelector('#required-listing-updates')) {
        new RequiredListingUpdatesForm().init();
      }
    }
  });
});
class Pets {
  getHtml({schedulingRestrictions, petsQuestionEnd, petsQuestionStart, petsQuestionMid}, questionDiv) {
    let select = '';
    if (JSON.parse(schedulingRestrictions)) {
      select =`<select class="partialform-edit will_wont_select" 
      name="question[][correct_answer]"
      style="display: inline-block;"> <option value="false">Do not allow</option>
      <option value="true" selected="selected">Allow</option></select>
       ${petsQuestionEnd}</small></span>`;
    }
    questionDiv.find('[data-binding="text"]')
      .val(petsQuestionStart)
      .wrap('<span class="system_question"></span>')
      .after('<br><small>' + petsQuestionMid + select);
    
    return questionDiv;
  }

  bindEvents() {}

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  isSingleQuestion() {
    return false;
  }
}

window.Pets = Pets;
import FetchUtils from 'src/utils/fetch_utils';

class ImageUploader {
  #wrapper;
  #imageable_type;
  #imageable_id;
  #use;

  getImageIds(){
    let ids=[];
    document.querySelectorAll('.partialform-edit li.thumbnail-photo img').forEach((img) => {
      ids.push(img.id.split('_')[1]);
    });
    return ids.reverse();
  }

  bindEvents() {
    this.#wrapper.querySelectorAll('a.delete_image_button').forEach((link) => {
      link.addEventListener('click', (e) => {
        const image_id = e.target.closest('.thumbnail-photo').querySelector('img').id.split('_')[1];
        this.deleteImage(image_id);
      });
    });

    this.#wrapper.querySelector('a.js-edit-photo-done').addEventListener('click', () => {
      this.reorderImages();
    });
  }

  getUrl(add) {
    let parts = [this.#imageable_type, this.#imageable_id, 'images'];
    parts.push(add);
    return '/' + parts.join('/');
  }

  deleteImage(image_id) {
    fetch(this.getUrl(image_id), {
      method: 'DELETE',
      headers: {'X-CSRF-Token': FetchUtils.CSRFToken(), 'Content-Type': 'application/json'},
    }).then(response => response.json()).then((params) => {
      if (params.success) {
        const img = document.querySelector(`#js-photo-list img#image_${image_id}`);
        img.closest('.thumbnail-photo').classList.add('removed');
        setTimeout(() => {
          img.closest('.thumbnail-photo').remove();
        }, 330);
      }
    }).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }

  reorderImages() {
    fetch(this.getUrl('reorder'), {
      method: 'POST',
      headers: {'X-CSRF-Token': FetchUtils.CSRFToken(), 'Content-Type': 'application/json'},
      body: JSON.stringify({
        ids: this.getImageIds(),
        id:  this.#imageable_id,
        use: this.#use
      })
    }).then(response => response.json()).then((params) => {
      document.querySelector(`.js-photo-section[data-use="${this.#use}"] .js-photo.photo_settings`).innerHTML = params.html;

      // Autorefresh craigslist html
      document.querySelector('body').dispatchEvent(new CustomEvent('listing-changed'));

      document.querySelector(`.js-photo-section[data-use="${this.#use}"] .js-image-uploader`).dispatchEvent(new CustomEvent('partial-form:disable'));

      document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: `.js-photo-section[data-use="${this.#use}"]`, imageUploader: true}}));
    }).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }

  initSortable() {
    document.querySelectorAll('.sortable').forEach(el => {
      if (!Sortable.get(el)) {
        new Sortable(el);
      }
    });
  }

  init(wrapper) {
    this.#wrapper        = wrapper;
    this.#imageable_type = wrapper.dataset.type;
    this.#imageable_id   = wrapper.dataset.imageableId;
    this.#use            = wrapper.dataset.use;
    // Dropzone config
    Dropzone.options.jsDropzone = {
      acceptedFiles      : 'image/*',
      dictDefaultMessage : 'Drop photos here to upload'
    };

    Dropzone.autoDiscover = false;

    this.initSortable();

    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initPhotos(container) {
    document.querySelectorAll(`${container || '.js-photo-section'}`).forEach((elem) => {
      new ImageUploader().init(elem);
    });
  }

  initPhotos();

  document.addEventListener('html-updated', (e) => {
    if (e.detail && e.detail.imageUploader) {
      initPhotos(e.detail.container);
    }
  });
});